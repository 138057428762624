<template>
  <div class="container">
    <div class="row passcode nonprintable">
          <button class="btn-sm bg-primary" @click="generatePassword">Generate Password</button>
          <input id="myPasscode" v-if="generated_passcode" type="text" v-model="generated_passcode"
          placeholder="Nouveau Mot de passe encore" />
          <button v-if="generated_passcode" class="btn-sm bg-primary" @click="copyPasscode">Copier</button>
      </div>
    <div class="form">
      <div class="field">
        <label for="username">Username:</label>
        <input id="username" type="text" v-model="username"
          placeholder="Nom d'utilisateur" />
      </div>
      <div class="field">
        <label for="old_password">Ancien Mot de passe:</label>
        <input id="old_password" type="password" v-model="old_password"
          placeholder="Ancien Mot de passe" />
      </div>
      <div class="field">
        <label for="new_password">Nouveau Mot de passe:</label>
        <input id="new_password" type="password" v-model="new_password"
          placeholder="Nouveau Mot de passe" />
      </div>
      <label class="logs">{{ pass_logs }}</label>
      <div class="field">
        <label for="new_password_2">Retaper le mot de passe:</label>
        <input id="new_password_2" type="password" v-model="new_password_2"
          placeholder="Nouveau Mot de passe encore" />
      </div>
      <div class="field">
        <label class="logs">{{ logs }}</label>
      </div>
      <div class="buttons">
        <button class="btn bg-danger" @click="deconnecter">
          <fa icon="sign-out-alt"/> Deconnexion
        </button>
        <button class="btn" @click="changePassword" :v-if="is_valid">
          Changer
        </button>
      </div>     
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      logs: "",
      old_password:"",
      new_password:"",
      new_password_2:"",
      pass_logs:"",
      username:"",
      is_valid:false,
      generated_passcode:""
    };
  },
  watch:{
    new_password(new_val){
      this.isPasswordWeak(new_val)
    },
    new_password_2(new_val){
      if(new_val.length==0) this.logs=""
      if(new_val!=this.new_password){
        this.logs = "les nouveaux mot de passe ne se ressemble pas"
      } else {
        this.logs=""
        this.is_valid = true
      }
    }
  },
  methods: {
    isPasswordWeak(password){
      if(password.length<8){
        this.pass_logs = "trop court"
      }else if(new Set(password.split("")).size<5){
        this.pass_logs = "moins de 6 different caractère"
      }else if((password == password.toUpperCase()) || (password==password.toLowerCase())){
        this.pass_logs = "melangez majiscules et miniscules"
      }else if((password.match(/[0-9]+/) == null) || (password.match(/[a-zA-Z]+/) == null)){
        this.pass_logs = "melangez lettres et chiffres"
      } else{
        this.pass_logs = ""
      }
    },
    generatePassword() {
      var length = 64,
          charset = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=;/{}:|<>?`,
          retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      console.log("inside")
      this.generated_passcode=retVal
      this.new_password=retVal;
    },
   copyPasscode() {
        var copyText = document.getElementById("myPasscode");
        copyText.style.backgroundColor = 'green';
        copyText.style.color = 'white';
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        alert("Mot de passe copié avec success ")
    },
    changePassword() {
      if(!this.is_valid){
        this.logs = "mot de passe trop faible"
        return
      }
      let data = {
        "username":this.username,
        "old_password":this.old_password,
        "new_password":this.new_password
      }
      axios.post(this.url +`/user/changepassword/`, data, this.headers)
      .then((response) => {
        alert("OK");
        this.$emit("close");
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.affectation);
      });
    },
    deconnecter(){
      this.$store.state.user = null
    }
  }
};
</script>
<style scoped>
.container{
  min-height: calc(100vh - 130px);
}
.form{
  width: 400px;
  padding: 30px;
  margin: auto;
  background-color: #fff5;
}
.field label{
  color: var(--primary);
  font-weight: 700;
}
input{
  border: 2px solid var(--primary);
}
.buttons{
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}
button{
  margin: 0;
}
.passcode{
  display:flex;
  justify-content:flex-start;
}
.passcode button{
  margin-right:30px;
}

.passcode input{
  width:50%;
}
</style>
