<template>
  <div class="container compt-pads">
    <div class="head">
      <div><h1>BILAN</h1></div>
      <div class="form">
        <div>Selectionner l'année:</div>
        <select id="" v-model="annee">
          <option class="option" v-for="year in years" :value="year">
            {{ year }}
          </option>
        </select>
        <button @click="fetchData(annee)" class="btn-sm bg-primary">
          Generer un Bilan
        </button>
      </div>
    </div>
    <div class="table">
      <div class="entete">
        <div class="info">
          <div>NOM:</div>
          <div>NIF:</div>
          <div>Adresse:</div>
        </div>
        <div class="title">
          <div>BILAN</div>
          <div>Exercice clos le .../.../{{ annee }}</div>
        </div>
      </div>
      <SpinnerComp v-if="loading"/>
      <div class="tables" v-else>
        <div>
          <table>
            <tr>
              <td rowspan="2">Compte</td>
              <td rowspan="2">ACTIF</td>
              <td colspan="3">ANNEE {{ annee }}</td>
              <td>ANNEE {{ annee - 1 }}</td>
            </tr>
            <tr>
              <td>Mont. Brut</td>
              <td>Amot/Prov.</td>
              <td>Mont. Net</td>
              <td>Mont. Net</td>
            </tr>
            <tr v-for="bilan in bilans_passifs"
              :class="{ 'thead': bilan.plan_comptable.length == 2}">
              <td>{{ bilan.plan_comptable }}</td>
              <td>{{ bilan.nom }}</td>
              <td class="money">{{ money(bilan.montant_brut) }}</td>
              <td>0</td>
              <td class="money">{{ money(bilan.montant_brut) }}</td>
              <td class="money">{{ money(bilan.ancien_montant) }}</td>
            </tr>
            <tr>
              <td colspan="2">Total</td>
              <td class="money">{{ money(totaux(bilans_passifs, 'montant_brut')) }}</td>
              <td></td>
              <td class="money">{{ money(totaux(bilans_passifs, 'montant_brut')) }}</td>
              <td class="money">{{ money(totaux(bilans_passifs, 'ancien_montant')) }}</td>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <td rowspan="2">Compte</td>
              <td rowspan="2">PASSIF ET FONDS PROPRES</td>
              <td>ANNEE {{ annee }}</td>
              <td>ANNEE {{ annee - 1 }}</td>
            </tr>
            <tr>
              <td>Mont. Net</td>
              <td>Mont. Net</td>
            </tr>
            <tr
              :class="{ 'thead': bilan.plan_comptable.length == 2}"
              v-for="bilan in bilans_actifs">
              <td>{{ bilan.plan_comptable }}</td>
              <td>{{ bilan.nom }}</td>
              <td class="money">{{ money(bilan.montant_brut) }}</td>
              <td class="money">{{ money(bilan.ancien_montant) }}</td>
            </tr>
            <tr>
              <td colspan="2">Total</td>
              <td class="money">{{ money(totaux(bilans_actifs, 'montant_brut')) }}</td>
              <td class="money">{{ money(totaux(bilans_actifs, 'ancien_montant')) }}</td>
            </tr>
          </table>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import SpinnerComp from "@/components/spinner_comp"

export default {
  components:{
    SpinnerComp
  },
  data() {
    return {
      loading: true,
      el: ".form",
      bilans: [],
      bilans_actifs: [],
      bilans_passifs: [],
      annee: new Date().getFullYear(),
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear() + 1;
      return Array.from(
        { length: year - 2023 }, (value, index) => 2023 + index
      );
    },
  },
  methods: {
    fetchDataActif(annee) {
      this.loading = true
      if (!annee) annee = this.annee;
      axios
        .get(
          this.url +
            `/bilan_comptables/?plan_comptable__category=PASSIF&annee=${annee}`,
          this.headers
        )
        .then((res) => {
          this.bilans_actifs = res.data.results;
        })
        .catch((err) => {
          this.displayErrorOrRefreshToken(err, this.fetchDataActif);
        }).finally(() => {
          this.loading = false
        });
    },
    fetchDataPassif(annee) {
      this.loading = true
      if (!annee) annee = this.annee;
      axios
        .get(
          this.url +
            `/bilan_comptables/?plan_comptable__category=ACTIF&annee=${annee}`,
          this.headers
        )
        .then((res) => {
          this.bilans_passifs = res.data.results;
        })
        .catch((err) => {
          this.displayErrorOrRefreshToken(err, this.fetchDataPassif);
        }).finally(() => {
          this.loading = false
        });
    },
    fetchData(annee) {
      this.loading = true
      if (!!annee) annee = this.annee;
      let data = { year: this.annee }
      axios.post(this.url + `/bilan_comptables/generate_bilan/`, data, this.headers)
        .then((res) => {
          this.fetchDataActif();
          this.fetchDataPassif();
        })
        .catch((err) => {
          this.displayErrorOrRefreshToken(err, this.fetchData);
        }).finally(() => {
          this.loading = false
        });
    },
    totaux(classe, colonne) {
      let doubled = classe.filter(x => x.plan_comptable.length == 2)
      return doubled.reduce((acc, x) => acc + x[colonne], 0);
    },
  },
  mounted() {
    this.fetchDataActif();
    this.fetchDataPassif();
  },
};
</script>
<style scoped>
.form {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff3;
  display: flex;
  align-items: center;
  gap: 5px;
}
.entete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.info {
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  flex-direction: column;
}
.head {
  display: flex;
  flex-direction: column;
}
.tables{
  display: flex;
  gap: 10px;
  font-size: .8em;
}
.thead {
  background-color: rgb(227, 187, 77);
}
.table {
  width: 100%;
}
th, td {    
	border-right: 1px solid #aaa;
}
.compt-pads {
    min-height: calc(100vh - 110px);
}
</style>
