<template>
  <Overlay @close="$emit('close')">
    <div class="field">
      <label for="montant">Montant:</label>
      <input id="montant" type="number" v-model="montant">
      <small v-if="montant">{{ money(montant) }}</small>
    </div>
    <div class="field">
      <label for="date">Date:</label>
      <input id="date" type="date" v-model="date">
    </div>
    <div class="field">
      <label for="crediteur">Compte crediteur:</label>
      <input id="crediteur" type="text" v-model="crediteur">
    </div>
    <div class="field">
      <label for="debiteur">Compte debiteur:</label>
      <input id="debiteur" type="text" v-model="debiteur">
    </div>
    <div class="field">
      <label for="details">Details:</label>
      <textarea id="details" v-model="details">
      </textarea>
    </div>
    <div class="field">
      <label class="logs">{{ logs }}</label>
    </div>
    <button class="btn" @click="postData">
      Soumettre
    </button>
  </Overlay>
</template>
<script>
import Overlay from "../dialog_overlay"
export default {
  components:{Overlay},
  props:["autreProps"],
  data(){
    return {
      montant:0,
      details:"",
      crediteur:null,
      date:"",
      debiteur:"",
      logs:""
    }
  },
  watch:{
    autreProps(new_val){
      if (!!new_val) {
        this.montant = new_val.montant
        this.details = new_val.details
        this.crediteur = new_val.crediteur
        this.debiteur = new_val.debiteur
        this.date = new_val.date.split("T")[0]
      }else{
        this.montant = 0
        this.details = 0
        this.crediteur = ""
        this.date = null
      }
    }
  },
  computed:{
    PASSIFS_ET_FONDS(){
      return Object.entries(this.$store.state.PASSIFS_ET_FONDS)
    },
  },
  methods:{
    postData(){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      let data = {
        montant:this.montant,
        details:this.details,
        debiteur:this.debiteur,
        crediteur:this.crediteur,
        date:this.date
      }
      if(!this.autreProps)
        axios.post(this.url+"/autres/", data, this.headers )
        .then((response) => {
          this.$store.state.autres.push(response.data)
          this.$emit("close")
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.postData)
        })
      else{
        axios.patch(this.url+"/autres/"+this.autreProps.id+"/", data, this.headers )
        .then((response) => {
          this.updateArrayAfterUpdate(
            this.$store.state.autres,
            response.data
            )
          this.$emit("close")
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.postData)
        })
      }
    }
  }
};
</script>
<style scoped>
.content{
  width: 150px!important;
}
</style>
