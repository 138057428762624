<template>
  <div class="container compt-pads">
    <div class="head">
      <div><h1>Grand Livre</h1></div>
      <div class="form">
        <div>Selectionner la date:</div>
        <input type="date" v-model="du" />
        <input type="date" v-model="au" />
        <button @click="fetchData(date)" class="btn-sm bg-primary">
          Filter
        </button>
      </div>
    </div>
    <SpinnerComp v-if="loading"/>
    <div class="light" v-for="pair in Object.entries(journals)" v-else>
      <div class="between">
        <div>
          <h3>{{ pair[0] }}</h3>
        </div>
        <div>
          <div v-if="!!du">
            Du {{ onlyDate(du) }} au {{ onlyDate(au) }}
          </div>
        </div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Compte</th>
              <th>Date</th>
              <th>Référence</th>
              <th>Description</th>
              <th>Débit</th>
              <th>Crédit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pair[1][0].initial.total > 0">
              <td colspan="4">Solde Initial</td>
              <td class="money">
                {{ money(pair[1][0].initial.total) }}
              </td>
              <td></td>
            </tr>
            <tr v-else>
              <td colspan="4">Solde Initial</td>
              <td></td>
              <td class="money">
                {{ money(Math.abs(pair[1][0].initial.total)) }}
              </td>
            </tr>
            <tr v-for="journal in pair[1]">
              <td>{{ pair[0] }}</td>
              <td>{{ journal.date }}</td>
              <td>{{ journal.ref }}</td>
              <td>{{ journal.description }}</td>
              <td class="money">{{ money(journal.debit) || "-" }}</td>
              <td class="money">{{ money(journal.credit) || "-" }}</td>
            </tr>
            <tr v-if="getSoldeFinal(pair[1]) > 0">
              <td colspan="4">Solde Debiteur</td>
              <td></td>
              <td class="money">
                {{ money(getSoldeFinal(pair[1])) }}
              </td>
            </tr>
            <tr v-else>
              <td colspan="4">Solde Crediteur</td>
              <td class="money">
                {{ money(Math.abs(getSoldeFinal(pair[1]))) }}
              </td>
              <td></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2">Total Caisse</th>
              <th colspan="2">Totaux</th>
              <th class="money">{{ money(getSoldeMax(pair[1])) }}</th>
              <th class="money">{{ money(getSoldeMax(pair[1])) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import SpinnerComp from "@/components/spinner_comp"

export default {
  components:{
    SpinnerComp
  },
  data() {
    return {
      loading: true,
      journals: {},
      journal_shown: false,
      du: new Date(),
      au: new Date(),
    };
  },
  methods: {
    fetchData() {
      this.loading = true
      this.journals = {}
      let url = this.url + "/journal_caisses/";
      let du = `${new Date(this.du).toISOString().split("T")[0]}`
      let au = `${new Date(this.au).toISOString().split("T")[0]}`
      this.headers.params = { date__gte: du, date__lte: au }
      this.loading = true
      axios.get(url, this.headers)
      .then((res) => {
        for (let item of res.data) {
          let key = `${item.plan_comptable} ${item.compte}`;
          if (this.journals[key] == undefined) {
            this.journals[key] = [item];
          } else {
            this.journals[key].push(item);
          }
        }
      }).catch((err) => {
        this.displayErrorOrRefreshToken(err, this.fetchData);
      }).finally(() => {
        this.loading = false
      });
    },
    getSoldeFinal(journals){
      let total_credit = journals.reduce((acc, x) => acc + x.credit, 0)
      let total_debit = journals.reduce((acc, x) => acc + x.debit, 0)
      let diff = total_debit - total_credit
      return journals[0].initial.total + diff
    },
    getSoldeMax(journals){
      let total_credit = journals.reduce((acc, x) => acc + x.credit, 0)
      let total_debit = journals.reduce((acc, x) => acc + x.debit, 0)
      if(journals[0].initial.total > 0){
        return Math.max(total_credit, total_debit + journals[0].initial.total)
      } else {
        return Math.max(total_credit + Math.abs(journals[0].initial.total), total_debit)
      }
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.form {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff3;
  display: flex;
  align-items: center;
  gap: 5px;
}
.light {
  background-color: #fff3;
  padding: 0 10px 10px 10px;
}
.between {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.head {
  display: flex;
  flex-direction: column;
}
.table {
  width: 100%;
}
th, td {    
	border-right: 1px solid #aaa;
}
.compt-pads {
    min-height: calc(100vh - 110px);
}
</style>
