<template>
  <div class="container compt-pads">
    <div class="head">
      <div><h1>Journal Caisses</h1></div>
      <div class="form">
        <div>Selectionner la date:</div>
        <input type="date" v-model="du" />
        <input type="date" v-model="au" />
        <button @click="fetchData()" class="btn-sm bg-primary">
          Filter
        </button>
      </div>
    </div>
    <SpinnerComp v-if="loading"/>
    <div class="table" v-else>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Référence</th>
            <th colspan="2">Comptes</th>
            <th>Libellé</th>
            <th colspan="2">Montant</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>Débit</th>
            <th>Crédit</th>
            <th></th>
            <th>Débit</th>
            <th>Crédit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="journal in journals">
            <td>{{ journal.date }}</td>
            <td>{{ journal.ref }}</td>
            <td class="money">
              {{ money(journal.debit) != 0 ? journal.plan_comptable : "-" }}
            </td>
            <td class="money">
              {{ money(journal.credit) != 0 ? journal.plan_comptable : "-" }}
            </td>
            <td>{{ journal.description }}</td>
            <td class="money">{{ money(journal.debit) || "-" }}</td>
            <td class="money">{{ money(journal.credit) || "-" }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="5">Total</th>
            <th class="money">{{ money(journals.reduce((acc, x) => acc + x.debit, 0)) }}</th>
            <th class="money">{{ money(journals.reduce((acc, x) => acc + x.credit, 0)) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import SpinnerComp from "@/components/spinner_comp"

export default {
  components:{
    SpinnerComp
  },
  data() {
    return {
      loading: true,
      journals: [],
      du: new Date(),
      au: new Date(),
    };
  },
  methods: {
    fetchData() {
      let url = this.url + "/journal_caisses/";
      let du = `${new Date(this.du).toISOString().split("T")[0]}`
      let au = `${new Date(this.au).toISOString().split("T")[0]}`
      this.headers.params = { date__gte: du, date__lte: au }
      this.loading = true
      axios.get(url, this.headers)
      .then((res) => {
        this.$store.state.journals = res.data;
        this.journals = this.$store.state.journals;
      }).catch((err) => {
        this.displayErrorOrRefreshToken(err, this.fetchData);
      }).finally(() => {
        this.loading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.form {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff3;
  display: flex;
  gap: 10px;
}
.head {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.table {
  width: 100%;
}
th, td {    
	border-right: 1px solid #aaa;
}
.compt-pads {
    min-height: calc(100vh - 110px);
}
</style>
