//@ts-nocheck
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'

import Agence from '@/views/menu/Agence'
import Guichet from '@/views/menu/Guichet'
import Credit from '@/views/menu/Credit'
import Placement from '@/views/menu/Placement'
import Utilisateur from '@/views/menu/Utilisateur'
import Cheque from '@/views/menu/Cheque'
import Quittances from '@/views/menu/Quittances'

import HistCompte from '@/views/historiques/HistCompte'
import HistGuichetier from '@/views/historiques/HistGuichetier'
import HistPrincipal from "@/views/historiques/HistPrincipal.vue"
import HistAgence from "@/views/historiques/HistAgence.vue"
import Rapport from "@/views/historiques/Rapport.vue"

import Amortissement from '@/views/Amortissement'
import AmortissementPlac from '@/views/AmortissementPlac'
import Profile from '@/views/Profile.vue'
import AgenceRemiseReprise from '@/views/AgenceRemiseReprise'
import Parametres from '@/views/Parametres'
import QuittancePrint from '@/views/QuittancePrint'
import ChequesPrint from '@/views/ChequesPrint'
import EpargneTab from "@/views/compte/EpargneTab.vue"

import Charges from '@/views/mouvements/Charges'
import Produits from '@/views/mouvements/Produits'
import Investissements from '@/views/mouvements/Investissements'
import Passifs from '@/views/mouvements/Passifs'
import Autres from '@/views/mouvements/Autres'
import DayHistorique from "@/views/DayHistorique.vue"

/* --------------------------- comptabilite views --------------------------- */
import Balance from '@/views/comptabilite/Balance.vue'
import Bilan from '@/views/comptabilite/Bilan.vue'
import CompteResultat from '@/views/comptabilite/CompteResultat.vue'
import FluxTresorerie from '@/views/comptabilite/FluxTresorerie.vue'
import GrandLivre from '@/views/comptabilite/GrandLivre.vue'
import Journal from '@/views/comptabilite/Journal.vue'
import PlanComptable from '@/views/comptabilite/PlanComptable.vue'
import ActiviteComptable from '@/views/comptabilite/Activite.vue'


const routes = [
  { path: "/", name:"home", component:Home},
  { path: "/guichet", name:"guichet", component:Guichet},
  { path: "/credit", name:"credit", component:Credit},
  { path: "/cheque", name:"cheque", component:Cheque},
  { path: "/quittances", name:"quittances", component:Quittances},
  { path: "/agence", name:"agence", component:Agence},
  { path: "/agence-remise-reprise/:id", name:"agence-mva", component:AgenceRemiseReprise},
  { path: "/placement", name:"placement", component:Placement},
  { path: "/utilisateur", name:"utilisateur", component:Utilisateur},
  { path: "/history-compte/", name:"listhistory", component:HistCompte},
  { path: "/history-compte/:id", name:"history", component:HistCompte},
  { path: "/hist-user/", name:"histguichet", component:HistGuichetier},
  { path: "/hist-user/:id", name:"listhistguichet", component:HistGuichetier},
  { path: "/amortissement/:id", name:"amortissement", component:Amortissement},
  { path: "/amortissement-p/:id", name:"amortissement_p", component:AmortissementPlac},
  { path: "/profile/:id", name:"profile", component:Profile},
  
  { path: "/charges", name:"charges", component:Charges},
  { path: "/charges/:id", name:"edit_charges", component:Charges},
  { path: "/produits", name:"produits", component:Produits},
  { path: "/produits/:id", name:"edit_produits", component:Produits},
  { path: "/investissements", name:"investissements", component:Investissements},
  { path: "/investissements/:id", name:"edit_investissements", component:Investissements},
  { path: "/passifs", name:"passifs", component:Passifs},
  { path: "/passifs/:id", name:"edit_passifs", component:Passifs},
  { path: "/autres", name:"autres", component:Autres},
  { path: "/autres/:id", name:"edit_autres", component:Autres},

  { path: "/parametres", name:"parametres", component:Parametres},
  { path: "/quittances-print/:range", name:"quittances_design", component:QuittancePrint},
  { path: '/epargne-list/:id',name:'epargne_list',component:EpargneTab},
  { path: '/print-cheques',name:'chequesPrint',component:ChequesPrint},
  
  /* --------------------------- comptabilite routes -------------------------- */
  { path: "/comptabilite/balance", name:"balance", component:Balance},
  { path: "/comptabilite/bilan", name:"bilan", component:Bilan},
  { path: "/comptabilite/compte-resultat", name:"compteresultat", component:CompteResultat},
  { path: "/comptabilite/flux-tresorerie", name:"fluxtresorerie", component:FluxTresorerie},
  { path: "/comptabilite/grandlivre", name:"grandlivre", component:GrandLivre},
  { path: "/comptabilite/journal", name:"journal", component:Journal},
  { path: "/comptabilite/plancomptable", name:"plancomptable", component:PlanComptable},
  { path: '/activite-comptable/:id',name:'activite_comptable',component:ActiviteComptable},

  { path: "/day-history", name:"day_history", component:DayHistorique},
  { path: "/main-history", name:"main_history", component:HistPrincipal},
  { path: "/agence-history", name:"agence_history", component:HistAgence},
  { path: "/rapport", name:"rapport", component:Rapport},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
