<template>
  <div class="container">
    <div class="row">
      <button @click="addMember" class="add">
        Nouveau Placement
      </button>
      <div>
        <input type="text" placeholder="rechercher" v-model="keyword">
        <button class="search">Rechercher</button>
      </div>
    </div>
    <div class="row" style="margin: 10px auto;">
      <p>Total placements en cours : {{money(stats.placements_en_cours)}} Fbu</p>
      <p>Total interêts delivrés : {{money(stats.interets_delivres)}} Fbu</p>
      <p>Total interêts restants : {{money(stats.interets_restants)}} Fbu</p>
    </div>
    <div class="table">
      <table> 
        <thead>
          <tr>
            <th>No. compte</th>
            <th>Nom</th>
            <th>Date</th>
            <th>Taux</th>
            <th>Periode</th>
            <th>Somme</th>
            <th>interval</th>
            <th>Options</th>
            <th>Situation</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="placement in $store.state.placements"
            @click="$router.push('/amortissement-p/'+placement.id)"
            :class="{'approved':placement.approved}">
            <td>{{ placement.account.account_number }}</td>
            <td>{{ placement.account.first_name+" "+placement.account.last_name }}</td>
            <td> {{ datetime(placement.date) }}</td>
            <td>{{ placement.interest_rate }}%</td>
            <td>{{ placement.period_time }} mois</td>
            <td>{{ money(placement.amount) }} FBu</td>
            <td>{{ placement.intervalle }} mois</td>
            <td>
              <div >
                <button v-if="!placement.approved" class="btn-sm bg-success"
                  @click.stop="validatePlacement(placement)">
                  <fa icon="check"/>
                </button>
                <button v-if="!placement.approved" class="btn-sm bg-danger"
                  @click.stop="deletePlacement(placement)">
                  <fa icon="times"/>
                </button>
                <button v-if="!placement.done" class="btn-sm bg-danger"
                  @click.stop="cloturerPlacement(placement)">
                  Cloturer
                </button>
              </div>
            </td>
            <td :class="placement.done?'done':'not-done'">{{ placement.done?'Terminé':'En cours...' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <DialogPlacement @close="close" :class="{'hidden':!dialog_shown}"/>
    <DialogCloturer
      @close="close"
      :class="{'hidden':!cloturer_shown}"
      :detailsProps="detailsObj"
      :placementProps="placementObj"
      />
    <DialogDateCloturer
      @close="close"
      :class="{'hidden':!date_close_shown}"
      :placementProps="placementObj"
      @closePlCalculated="handlerClosePlCalculated"
    />
  </div>
</template>
<script>
import DialogPlacement from "@/components/dialog_placement"
import DialogCloturer from "@/components/dialog_cloturer_pl"
import DialogDateCloturer from "@/components/dialog_date_close_pl"
export default {
  props: {
  },
  components:{DialogPlacement,DialogCloturer,DialogDateCloturer},
  data(){
    return {
      dialog_shown:false, keyword:"", next:null, stats:[],detailsObj:null,cloturer_shown:false,
      placementObj:{}, date_close_shown:false
    }
  },
  methods:{
    addMember(){
      this.dialog_shown = true;
    },
    close(){
      this.dialog_shown = false;
      this.cloturer_shown=false;
      this.date_close_shown=false;
    },
    validatePlacement(placement){
      let confirm = this.active_user.first_name+" "+this.active_user.last_name;
      if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
        axios.get(this.url+`/placement/${placement.id}/validate/`, this.headers)
        .then((response) => {
          placement.approved = true;
          placement.approved_by = this.active_user
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.deletePlacement)
        });
      } else {
        alert("ABANDONÉE")
      }
    },
    cloturerPlacement(placement){
      this.date_close_shown = true
      this.placementObj = placement
    },
    handlerClosePlCalculated(e,id){
      console.log(e)
      console.log(id)
      this.close()
      this.cloturer_shown=true
      this.detailsObj=e
      this.placementObj=id.id
    },
    deletePlacement(placement){
      let confirm = this.active_user.first_name+" "+this.active_user.last_name;
      if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
        axios.delete(this.url+`/placement/${placement.id}/`, this.headers)
        .then((response) => {
          let index = this.$store.state.placements.indexOf(placement)
          if(index >= 0) this.$store.state.placements.splice(index, 1)
            alert("FAIT")
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.deletePlacement)
        })
      } else {
        alert("ABANDONÉE")
      }
    },
    fetchStats(){
      axios.get(this.url+"/placement/stats/", this.headers)
      .then((res)=>{
        this.stats=res.data
      }).catch((error)=>{
        this.displayErrorOrRefreshToken(error, this.fetchStats)
      })
    },
    fetchPlacement(){
      let link;
      if(!this.next){
        this.$store.state.placements = []
        link = this.url+"/placement/"
      } else {
        link = this.next
      }
      axios.get(link, this.headers)
      .then((response) => {
        this.$store.state.placements.push(...response.data.results)
        if(response.data.next != null){
          this.next = response.data.next
          this.fetchPlacement()
        } else {
          this.next = null
        }
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.fetchPlacement)
      })
    },
  },
  mounted(){
    if(this.$store.state.placements.length<2){
      this.fetchPlacement()
    }
      this.fetchStats()
  },
};
</script>
<style scoped>
.container {
  min-height: calc(100vh - 130px);
}
.row>p{
  background-color:white;
  padding:7px;
  font-weight:bold;
}
</style>
