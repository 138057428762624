<template>
  <div class="container">
    <div class="header printable">
      <div class="right">
        <h2>ISIGI-MICROFINANCE</h2>
        <h3>KIBIMBA-GIHETA (SIEGE)</h3>
        <div>Tel: <b>+257 71 946 659/69 703 619</b></div>
      </div>
      <img src="/static/logo.png" width="100px" />
    </div>
    <div class="row nonprintable">
      <div class="left">
        <div class="field" v-if="is_date_exact">
          <label for="du">Date : </label>
          <input type="date" id="du" v-model="exact_date" />
        </div>
        <div class="field" v-if="!is_date_exact">
          <label for="du">Du : </label>
          <input type="date" id="du" v-model="du" />
        </div>
        <div class="field" v-if="!is_date_exact">
          <label for="au">Au : </label>
          <input type="date" id="au" v-model="au" />
        </div>
        <div class="field" v-if="active_user_is('cto', 'admin', 'responsable')">
          <label for="user_filter">Utilisateur: </label>
          <select v-model="user_filter">
            <option
              v-for="personnel in personnels_list"
              :value="personnel.user.id">
              {{ personnel.user.first_name }} {{ personnel.user.last_name }}
            </option>
            <option value="">Tout</option>
          </select>
        </div>
        <button class="btn-sm bg-primary" @click="filterDate">Filtrer</button>
      </div>
      <button class="btn-sm bg-primary" @click="imprimer">
        imprimer
      </button>
    </div>
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>Heure</th>
            <th>Details</th>
            <th v-if="active_user_is('cto', 'admin', 'responsable')">
              Options
            </th>
            <th v-if="active_user_is('cto', 'admin')">S.U</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in history" :key="record.id">
            <td>{{ datetime(record.date) }}</td>
            <td>{{ record.details }}</td>
            <td v-if="active_user_is('cto', 'admin', 'responsable')">
              <button
                class="btn-sm bg-danger nonprintable"
                @click="annuler(record.id)"
              >
                Annuler
              </button>
            </td>
            <td>
              <button
                class="btn-sm bg-danger"
                v-if="active_user_is('cto', 'admin')"
                @click="deleteHistory(record.id)"
              >
                <fa icon="trash" />
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="center">Total</th>
            <th class="money">
              Depot:
              <b>
                {{ money(history.reduce((acc, x) => (acc += depot(x)), 0)) }}
              </b>
              Retrait:
              <b>
                {{ money(history.reduce((acc, x) => (acc += retrait(x)), 0)) }}
              </b>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="footer printable">
      <div class="left">
        <div>
          <div>Guichetier</div>
          <h3>
            {{ getFullname(user_filter) }}
          </h3>
        </div>
      </div>
      <div class="right">
        <h3>Historique Validé par</h3>
        <small>(Nom et signature)</small>
      </div>
      <img src="/static/logo.png" width="100px">
    </div>
    <DialogHist
      :class="{ hidden: !dialog_shown }"
      @close="dialog_shown = false"
    />
  </div>
</template>
<script>
import DialogHist from "@/components/cto/dialog_create_hist";

export default {
  components: { DialogHist},
  data() {
    return {
      du: this.getToday(),
      au: this.getToday(),
      history: [],
      next: "",
      can_print: false,
      personnels_list: [],
      user_filter: this.$route.params.id,
      dialog_shown: false,
      is_date_exact: false,
    };
  },
  components: { DialogHist },
  methods: {
    getFullname(id){
      let personnel = this.personnels_list.filter(x => x.id == this.user_filter)[0]
      return `${personnel?.user?.first_name} ${personnel?.user?.last_name}`
    },
    depot(record) {
      if (record.action == "Depot") {
        return record.balance_compte_apres - record.balance_compte_avant;
      }
      return 0;
    },
    retrait(record) {
      if (record.action == "Retrait") {
        return record.balance_compte_avant - record.balance_compte_apres;
      }
      return 0;
      e;
    },
    fetchHistory() {
      let args = !!this.user_filter ? `?user=${this.user_filter}` : '';
      axios
        .get(this.url + `/historique/${args}`, this.headers)
        .then((response) => {
          this.history = response.data.results;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchHistory);
        });
    },
    openDialog() {
      this.dialog_shown = true;
    },
    annuler(hist_id) {
      if (this.active_user_is("readonly", "admin")) {
        this.$store.state.alert = {
          type: "danger",
          message: "Vous êtes en lecture seule",
        };
        return;
      }
      axios
        .get(this.url + `/historique/${hist_id}/annuler/`, this.headers)
        .then((response) => {
          this.history.unshift(response.data);
          alert("SUCCESS");
        })
        .catch((error) => {
          alert(JSON.stringify(error.response.data));
        });
    },
    fetchUsers() {
      axios
        .get(this.url + "/personnel/", this.headers)
        .then((response) => {
          this.personnels_list = response.data.results;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchUsers);
        });
    },
    filterDate() {
      let link;
      let date_arg;
      let du = !!this.du?`${this.du}T00:00`:''
      let au = !!this.au?`${this.au}T23:59`:''
      let period = `date__gte=${du}&date__lte=${au}&`;

      date_arg = `${period}user=${this.user_filter ?? this.id}`;

      if (!this.next) {
        link = this.url + `/historique/?${date_arg}`;
      } else {
        link = this.next;
      }
      axios
        .get(link, this.headers)
        .then((response) => {
          if (!response.data.previous) {
            this.history = response.data.results;
          } else {
            this.history.push(...response.data.results);
          }
          console.log(response.data.results);
          if (response.data.next != null) {
            this.next = response.data.next;
            this.filterDate();
          } else {
            this.next = null;
          }
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchHistory);
        });
    },
    imprimer() {
      if (this.active_user_is("readonly")) {
        this.$store.state.alert = {
          type: "danger",
          message: "Vous êtes en lecture seule",
        };
        return;
      }
      if (this.can_print) {
        print();
      } else {
        axios
          .get(this.url + `/historique/`, this.headers)
          .then((response) => {
            this.history.push(response.data);
            this.can_print = true;
          })
          .catch((error) => {
            alert(this.cleanString(error.response.data));
          });
      }
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchHistory();
  },
};
</script>
<style scoped>
.container {
  min-height: calc(100vh - 130px);
}
.row {
  width: 90%;
  margin: 10px auto;
}
.left {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
input[type="date"] {
  height: 28px;
  width: 200px;
  outline: none;
  padding-bottom: 2px;
  border: 2px solid var(--primary);
}
.header, .footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--primary);
  margin: 10px 0 10px 0;
  white-space: nowrap;
}
.footer{
  padding-bottom: 30px;
}
.printable{
  display: none !important;
}
@media print {
  .printable{
    display: flex !important;
  }
}
</style>
