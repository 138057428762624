<template>
  <div class="container compt-pads">
    <SpinnerComp v-if="loading"/>
    <div class="light" v-else>
      <div>
        <h3 v-if="journals.length > 0">{{ journals[0].compte }}</h3>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Référence</th>
            <th>Description</th>
            <th>Débit</th>
            <th>Crédit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="journal in journals">
            <td>{{ journal.date }}</td>
            <td>{{ journal.ref }}</td>
            <td>{{ journal.description }}</td>
            <td class="money">{{ money(journal.debit) || "-" }}</td>
            <td class="money">{{ money(journal.credit) || "-" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import SpinnerComp from "@/components/spinner_comp"

export default {
  components:{
    SpinnerComp
  },
  data() {
    return {
      loading: true,
      journals: [],
      date: new Date(),
    };
  },
  methods: {
    fetchData() {
      console.log(this.$route.params)
      this.loading = true
      let url = this.url + `/journal_caisses/?plan_comptable=${this.$route.params.id}`;
      axios.get(url, this.headers)
      .then((res) => {
        this.journals = res.data
      }).catch((err) => {
        this.displayErrorOrRefreshToken(err, this.fetchData);
      }).finally(() => {
        this.loading = false
      });
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.form {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff3;
  display: flex;
  align-items: center;
  gap: 5px;
}
.light {
  background-color: #fff3;
  padding: 0 10px 10px 10px;
}
.between {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.head {
  display: flex;
  flex-direction: column;
}
.table {
  width: 100%;
}
th, td {    
	border-right: 1px solid #aaa;
}
.compt-pads {
    min-height: calc(100vh - 110px);
}
</style>
