<template>
  <Overlay @close="$emit('close')">
    <div class="field">
      <label for="type_credit">Type de credit:</label>
      <select v-model="amortissement" id="type_credit">
        <option value="0">Amortissement Lineaire</option>
        <option value="1">Amortissement Degressive</option>
      </select>
    </div>

    <div class="field">
      <label for="confirmation">tapez "{{ active_user.first_name }}"</label>
      <input
        type="text"
        autocomplete="off"
        id="confirmation"
        v-model="confirmation"
        placeholder="mot de confirmation"
      />
    </div>
    <div class="field">

      <div class="group">
        <label>Frais Dossier</label>
        <input type="checkbox" class="checkbox" v-model="fraisDossier" />
      </div>

      <div class="group">
        <label>Assurance Credit</label>
        <input type="checkbox" class="checkbox" v-model="assuranceCredit" />
      </div>

      <div class="group">
        <label>Nantissement</label>
        <input type="checkbox" class="checkbox" v-model="nantissement" />
      </div>

      <div class="group">
        <label>Commision</label>
        <input type="checkbox" class="checkbox" v-model="comission" />
      </div>
    </div>
    <div class="field">
      <label class="logs">{{ logs }}</label>
    </div>
    <button class="btn" @click="demander">Soumettre</button>
  </Overlay>
</template>
<script>
import Overlay from "../dialog_overlay";
export default {
  components: { Overlay },
  props: ["credit"],
  data() {
    return {
      logs: "",
      amortissement: null,
      confirmation: "",
      fraisDossier: false,
      assuranceCredit: false,
      nantissement: false,
      comission: false,

      fraisDossier2: 0,
      assuranceCredit2: 0,
      nantissement2: 0,
      comission2: 0,
    };
  },
  watch: {
    fraisDossier(new_val) {
      if (new_val == true) {
        this.fraisDossier2 = 1;
      } else this.fraisDossier2 = 0;
    },
    assuranceCredit(new_val) {
      if (new_val == true) {
        this.assuranceCredit2 = 1;
      } else this.assuranceCredit2 = 0;
    },
    nantissement(new_val) {
      if (new_val == true) {
        this.nantissement2 = 1;
      } else this.nantissement2 = 0;
    },
    comission(new_val) {
      if (new_val == true) {
        this.comission2 = 1;
      } else this.comission2 = 0;
    },
  },
  methods: {
    demander() {
      if (this.active_user_is("readonly")) {
        this.$store.state.alert = {
          type: "danger",
          message: "Vous êtes en lecture seule",
        };
        return;
      }
      this.logs = "";
      if (this.confirmation != this.active_user.first_name) {
        this.logs = "confirmation incorrect";
        return;
      }
      let data = {
        fraisDossier: this.fraisDossier2,
        assuranceCredit: this.assuranceCredit2,
        nantissement: this.nantissement2,
        comission: this.comission2,
        is_degressif: this.amortissement,
      };
      axios
        .post(
          this.url + `/credit/${this.credit.id}/validate/`,
          data,
          this.headers
        )
        .then((response) => {
          this.credit.approved_by = this.active_user;
          this.$emit("close");
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.demander);
        });
    },
  },
};
</script>
<style scoped>
#frais {
  text-align: center;
}
.group {
  display: flex;
  font-size: medium;
}
.checkbox {
  width: 4%;
  margin-left: -60%;
}
</style>
