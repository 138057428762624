export default {
  methods: {
    isNumeric(x) {
      let str = x.toString();
      if (str.match(/^[0-9]+$/)) return true;
      return false;
    },
    nombre(x) {
      let cash = parseInt(x)
      if(!x) return "0";
      return cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    money(x) {
      let cash = parseFloat(x).toFixed(1)
      if(!x) return "0";
      return cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    cleanString(str){
      if ((str===null) || (str==='')){
        return ""
      }
      if(JSON.stringify(str).includes("Django")){
        return "données reçues invalides"
      };
      if(typeof(str)=='object'){
        let string = ""
        for( let [clef, valeur] of Object.entries(str)){
          if(typeof(valeur)=='object'){
            let child = ""
            for( let [key, value] of Object.entries(valeur)){
              child += `${key}: ${value} `
            }
            valeur = child;
          }
          string+=`${clef}: ${valeur}<br>`
        }
        return string;
      };
      str = str.toString();
      return str.replace(/(<style[\w\W]+style>)/g, "").replace( /(<([^>]+)>)/ig, '');
    },
    getGroupName(ids){
      let group;
      let names = []
      if(Array.isArray(ids)){
        for(let id of ids){
          group = this.$store.state.groups.find(x => x.id == id);
          names.push(group?.name || "-");
        }

      } else {
        group = this.$store.state.groups.find(x => x.id == ids);
        names.push(group?.name || "-");
      }
      return names.toString()
    },
    getGroupId(name){
      let group = this.$store.state.groups.find(x => {
        return x.name.toLowerCase() == name.toLowerCase()
      })
      return !!group? group.id:-1;
    },
    userIs(personnel, group_id){
      let groups = personnel.user.groups;
      return groups.includes(group_id);
    },
    datetime(x) {
      if(!x) return "-"
      let date = new Date(x);
    	return new Intl.DateTimeFormat(
    		'en-GB',
    		{ dateStyle: 'short', timeStyle: 'short' }
    	).format(date)
    },
    formatDateExcel(date) {
      if(date.includes("/")){
        let date1 = date.split("/")
        if(parseInt(date[0]>12))
          date=`${date1[2]}-${date1[1]}-${date1[0]}`
        else
          date=`${date1[2]}-${date1[0]}-${date1[1]}`
      }
      return date
    },
    onlyDate(x) {
      if(!x) return "-"
      let date = new Date(x);
      return new Intl.DateTimeFormat(
        'en-GB',
        { dateStyle: 'short' }
      ).format(date)
    },
    displayErrorOrRefreshToken(error, callback){
      if(!!error.response){
        if(error.response.data.code == "token_not_valid"){ 
          let refresh = this.$store.state.user.refresh
          if(!refresh){
            this.$store.state.user = null;
            return
          }
          axios.post(this.url+"/refresh/", {"refresh":refresh})
          .then((response) => {
            this.$store.state.user.access = response.data.access
            if(typeof callback == "function") callback()
          }).catch((error) => {
            this.$store.state.user = null;
            console.error(error)
            this.$store.state.alert = {
              type:"danger", message:this.cleanString(error.response.data)
            }
          })
        } else {
          console.error(error)
          this.$store.state.alert = {
            type:"danger", message:this.cleanString(error.response.data)
          }
        }
      } else {
        console.error(error)
      }
    },
    activateRow(e){
      let trs = document.getElementsByTagName("tr")
      let tr = e.target.closest("tr")
      for(let item of trs){
        item.classList.remove("active_row")
      }
      tr.classList.add("active_row")
    },
    getSexe(compte){
      if(compte.sexe == "H") return "Homme"
      if(compte.sexe == "F") return "Femme"
      return "N/A"
    },
    getAge(compte){
      if(!compte.naissance) return "-"
      let year = new Date().getFullYear() - compte.naissance
      return `${compte.naissance}(${year}ans)`
    },
    getToday(){
      return new Date().toISOString().split("T")[0]
    },
    deleteHistory(id){
      let confirm = this.active_user.first_name+" "+this.active_user.last_name;
      if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
        axios.delete(this.url+`/historique/${id}/`, this.headers)
        .then(() => {
          alert("Historique supprime avec success")
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.deleteHistory)
        })
      }else 
  			alert("ABANDONÉE")
    },
    active_user_is(...groups){
      let user_groups = this.active_user?.groups
      if(!!this.active_user){
        for (let group of groups) {
          if(user_groups.includes(group)){
            return true
          }
        }
      }
      return false
    },
    updateArrayAfterUpdate(arr,item){
      let id = arr.findIndex(x=> x.id===item.id)
      arr.splice(id,1,item)
    }
  },
  computed:{
    active_user(){
      return this.$store.state.user;
    },
    currentMonthDigit(){
      return new Date().getMonth() + 1;
    },
    url(){
      // let base_url = window.location.protocol+"//"+"192.168.43.121:8000"
      let base_url = ""
      let base_host = window.location.host.split(":")[0]
      let locals = ["localhost", "127.0.0.1"]
      if(locals.includes(base_host)){
        base_url = window.location.protocol+"//"+base_host+":8000"
      }
      return base_url + this.$store.state.api;
      // return window.location.protocol+"//"+'test.isigi-microfinance.com/api'
    },
    headers(){
      return {
      	headers:{
      		"Authorization":"Bearer "+this.$store.state.user.access
      	}
      }
    }
  }
}
