<template>
    <div>
        <button class="print-button nonprintable" @click="printCheques">Imprimer</button>
        <div class="cheque-main-container">
          <div v-for="cheque in $store.state.print_cheques">
            <div class="cheque-container" v-for="chequier in cheque.asked_number">
                <img src="/static/cheque.jpg">
                <div class="vars left-acc-num" >{{getCheque(cheque,chequier)}}</div>
                <div class="vars right-acc-num" >{{getCheque(cheque,chequier)}}</div>
                <div class="vars fullname" >{{cheque.compte.first_name}} {{cheque.compte.last_name}}</div>
                <div class="vars acc-number" >{{cheque.compte.account_number}}</div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
    export default{
        methods: {
          printCheques() {
            print()
          },
          nbChequier(cheque){
            return cheque.waiting+(cheque.asked_number-1)
          },
          getCheque(cheque, chequier){
            let nb = (cheque.waiting-1)+chequier
            return this.zfill(nb)
          },
          zfill(number){
            if(typeof(number)!="string")
                number=""+number
                let z_filled=""
                let range = 7-number.length
                for(let i=0;i<range;i++) z_filled+="0"
            return z_filled+number
            },
        },
        mounted(){
            if(!this.active_user_is('cto', 'admin')){
                this.$store.state.alert = {
                    type:"danger", message:"Permission reservé aux Directeurs"
                }
                this.$router.push("/")
            }
            if(this.$store.state.print_cheques.length == 0){
                this.$router.push("/cheque")
            }
        } 
    }
</script>
<style scoped>
        .cheque-main-container{
            width: 21cm;
            min-height: 29.7cm;
            margin: auto;
            background: white;
        }
        .cheque-container{
            position:relative;
            height:250px;
            max-height:250px;
        }
        .print-button{
            position:absolute;
            right:7%;
            top:100px;
            padding:5px;
            background-color:red;
            color:white;
        }
        img{
            width: 100%;
            max-height:250px;
        }
        .vars{
            font-weight:bold;
            font-size:11px;
        }

        .left-acc-num{
            position:absolute;
            bottom:33px;
            left:90px;
        }
        .right-acc-num{
            position:absolute;
            bottom:58px;
            left:262px;
        }
        .fullname{
            position:absolute;
            bottom:58px;
            left:414px;
        }
        .acc-number{
            position:absolute;
            bottom:85px;
            left:290px;
        }
        @media print {
            body{
                margin:0;
                padding:0;
            }
            @page 
            {
                size: auto; 
                margin: 0mm;
            }
            .vars{
                font-weight:bold;
            }
        }
</style>