<template>
  <Overlay @close="$emit('close')">
      <div class="field">
        <label for="epargne_end_date">Date de cloture:</label>
        <input id="epargne_end_date" type="date" v-model="epargne_end_date">
      </div>
      <div class="field">
        <label for="taux_interet">Taux d'interêt:</label>
        <input id="taux_interet" type="number" v-model="epargne_taux_interet">
      </div>
    <div class="field">
      <label class="logs">{{ logs }}</label>
    </div>
    <button class="btn" @click="createEpargne">
      Soumettre
    </button>
  </Overlay>
</template>
<script>
import Overlay from "./dialog_overlay"
export default {
  props:["item"],
  components:{Overlay},
  data(){
    return {
      epargne_end_date:"", logs:"", mouvement:"",
      epargne_taux_interet:""
    }
  },
  watch:{
    mouvement(new_val){
      console.log(new_val)
    }
  },
  methods:{
    createEpargne(){
      console.log(this.epargne_end_date)
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      let date = this.formatDateExcel(new Date().toLocaleDateString("en-CA", { format: 'short' }))
      let data = {
        epargne_end_date: this.epargne_end_date,
        epargne_start_date: date,
        epargne_taux_interet: this.epargne_taux_interet,
      }
      axios.post(this.url+`/compte/${this.item.id}/epargner/`, data, this.headers)
        .then((response) => {
          let index = this.$store.state.comptes.indexOf(this.item)
          this.$store.state.comptes[index] = response.data
          this.$emit("close")
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.createMember)
      })
    }
  }
};
</script>
<style scoped>
.radio-mouvement{
  display: flex;
  justify-content: space-around;
  margin: 20px;
  align-items: center;
}
</style>
