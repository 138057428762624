<template>
  <Overlay @close="$emit('close')">
    <div class="passifs">
      <input type="file" accept=".csv" 
        @change="e => prepareData(e)">
      <div v-if="passifs.length > 0">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Montant</th>
              <th>Category</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="passif in passifs">
              <td>{{ passif.date }}</td>
              <td>{{ money(passif.montant) }}</td>
              <td>{{ passif.category }}</td>
              <td>{{ passif.details }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="montant" v-if="montant">
        Total des passifs {{ money(montant) }}
      </div>
    </div>
    <button class="btn" @click="postData">
      Soumettre
    </button>
  </Overlay>
</template>
<script>
import Overlay from "../dialog_overlay"
export default {
  components:{Overlay},
  data(){
    return {
      passifs:[],
      montant: 0,
      logs:""
    }
  },
  computed:{
    CHARGES_TYPES(){
      return Object.entries(this.$store.state.CHARGES_TYPES)
    },
  },
  methods:{
    prepareData(event){
      this.passifs = []
      this.montant=0
      let file_path = event.target.files[0]
      var reader = new FileReader()
      reader.onload = (event) => {
          const file = event.target.result;
          const lines = file.split(/\r\n|\n/);
          let array_line = []
          let record
          lines.forEach((line) => {
            array_line = line.split(/;/);
            if(array_line.length>1){
              if(array_line[0].length > 2){
                record = {
                  "date":array_line[0].trim().split("/").reverse().join("-"),
                  "montant":array_line[1].trim().replace(",", ""),
                  "category":array_line[2].trim(),
                  "details":array_line[3].trim()
                }
                this.passifs.push(record)
                this.montant += parseInt(record.montant)
              }
            }
          });
      };
      reader.onerror = (event) => {
        alert(event.target.error.name);
      };
      reader.readAsText(file_path, 'ISO-8859-1');
    },
    postData(){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      axios.post(this.url+"/passif/", this.passifs, this.headers )
      .then((response) => {
        this.$store.state.passifs.push(...response.data)
        this.$emit("close")
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.postData)
      })
    },
  }
};
</script>
<style scoped>
.passifs{
  padding: 10px;
  max-width: 600px!important;
  font-size: .7em;
}
table{
  margin-top: 10px;
}
.montant{
  margin-top: 10px;
}
.btn{
  margin: 0 10px 0 auto;
}
</style>
