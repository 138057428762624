<template>
	<div>
		<div class="print-button nonprintable" @click="printQuittance">Imprimer</div>
		<div class="quit-container">
			<div class="card" v-for="i in tab" :ke="i">
				<div class="qt-headers">
					<img src="/static/logo.png">
					<div>ISIGI MICROFINANCE</div>
				</div>
				<div class="qt-op-type">
					<div class="depot">
						<p>Depot</p>
						<span></span>
					</div>
					<div class="retrait">
						<p>Retrait</p>
						<span></span>
					</div>
				</div>
				<br>
				<div class="qt-number">
					N°:{{zfill(i)}}
				</div>
				<br>
				<div class="qt-body">
					<p>
						<span>Effectué par………………………………………………..</span>
					</p>
					<p>
						<span>Titulaire du Compte:………………………………………</span>
					</p>
					<p>
						<span>Numéro du Compte:………………………………………</span>
					</p>
					<p>
						<span>Montant:……………………………………………………</span>
					</p>
					<p>
						<span>……………………………………………………………...</span>
					</p>
					<p>
						<span>Date et Signature (s)</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		data(){
			return{
				start:0,
				end:0,
				quittance_id:0,
				tab:[]
			}
		},
		beforeMount(){
			this.start = parseInt(this.$route.params.range.split("-")[0])
			this.end = parseInt(this.$route.params.range.split("-")[1])
			this.quittance_id = parseInt(this.$route.params.range.split("-")[2])
			for(let i=this.start;i<=this.end;i++){
				this.tab.push(i)
			}
		},
		methods:{
			zfill(number){
				if(typeof(number)!="string")
					number=""+number
				let z_filled=""
				let range = 7-number.length
				for(let i=0;i<range;i++){
					z_filled+="0"
				}
			return "ISIGI/"+z_filled+number
			},
			markAsPrinted(){
				axios.get(this.url+"/quittances/"+this.quittance_id+"/printed/", this.headers)
				.then(()=>{
					alert("Printed")
				}).catch((error)=>{
					this.displayErrorOrRefreshToken(error, this.markAsPrinted)
				})
			},
			printQuittance(){
				print()
				this.markAsPrinted()
			}
		}
	}
</script>
<style scoped>
		.quit-container{
			width: 21cm;
	        min-height: 29.7cm;
	        margin: auto;
	        background: white;
	        padding-left:15px;
		}
		.card{
			float: left;
			width:10cm;
			min-width:10cm;
			height:9.5cm;
			min-height:9.5cm;
			border: 1px solid black;
			border-radius: 10px;
			padding: 8px;
			margin-right:5px;
			padding-left:15px;
			margin-bottom:5px;
		}
		.qt-number{
			text-align:center;
		}
		.depot,.retrait{
			display: flex;
			justify-content: center;
			align-items: center;

		}
		.depot,.depot p{
			margin-right: 10px;
		}
		.retrait,.retrait span{
			margin-left: 10px;
		}
		.depot span, .retrait span{
			min-width: 33px;
			border: 1px solid black;
			height: 33px;
		}
		*{
			font-size: 11.0pt;
			line-height: 150%;
			font-family: "Tw Cen MT";
			font-weight: bold;
			language: fr-BE;
		}
		.qt-headers{
			margin-bottom: 10px;
			display:flex;
			justify-content:center;
			align-items:center;
		}
		.qt-op-type{
			margin-bottom: 10px;
			display: flex;
			justify-content: center;
		}
		@media print {
		    .quit-container { display: block; } /* this is key */
		    div, p,.card{ page-break-inside: avoid!important; }
		}
	img{
		width:90px;
		height:90px;
	}
	.print-button{
		position:absolute;
		right:7%;
		top:100px;
		padding:5px;
		background-color:red;
		color:white;
	}
</style>