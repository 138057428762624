import { createApp } from 'vue'
import axios from "axios"
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faCheck,
  faTimes,
  faUser,
  faSignOutAlt,
  faSearch,
  faUnlock,
  faPen,
  faLock,
  faListAlt,
  faTrash,
  faUsers,
  faPlay,
  faPause,
  faSms
} from '@fortawesome/free-solid-svg-icons'

library.add(faCheck)
library.add(faTimes)
library.add(faUser)
library.add(faUsers)
library.add(faSignOutAlt)
library.add(faSearch)
library.add(faUnlock)
library.add(faLock)
library.add(faPen)
library.add(faListAlt)
library.add(faTrash)
library.add(faPlay)
library.add(faPause)
library.add(faSms)

window.axios = axios;

createApp(App)
	.component('fa', FontAwesomeIcon)
	.mixin(mixins)
  .use(store)
	.use(router)
	.mount('#app')
