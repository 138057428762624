<template>
	<div class="container">
		<PrintableHeader/>
		<div class="row nonprintable">
			<div class="left">
				<div class="field">
					<label for="du">Année: </label>
					<select v-model="year">
						<option v-for="year in years" :value="year">{{ year }}</option>
					</select>
				</div>
				<button class="btn-sm bg-primary" @click="fetchComptes">Filtrer</button>
			</div>
			<div class="buttons">
				<button
					class="btn-sm bg-primary"
					@click="imprimer">
					Imprimer
				</button>
			</div>
		</div>
		<div class="table">
			<table>
				<thead>
					<tr>
						<th>Indicateur</th>
						<th>Janv</th>
						<th>Févr</th>
						<th>Mars</th>
						<th>Avr</th>
						<th>Mai</th>
						<th>Juin</th>
						<th>Juil</th>
						<th>Août</th>
						<th>Sept</th>
						<th>Oct</th>
						<th>Nov</th>
						<th>Dec</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div><b>Nombre de comptes clients ou membres</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(nb_membres)">
							<div>{{ nombre(nb_membres[m].hommes) }}</div>
							<div>{{ nombre(nb_membres[m].femmes) }}</div>
							<div>{{ nombre(nb_membres[m].associations) }}</div>
							<div>{{ nombre(nb_membres[m].cooperatives) }}</div>
							<div>{{ nombre(nb_membres[m].entreprises) }}</div>
							<div>{{ nombre(nb_membres[m].autres) }}</div>
							<div>{{ nombre(nb_membres[m].autres+nb_membres[m].femmes+nb_membres[m].hommes) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Nombre de compte innactifs ou dormant</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(nb_inactifs)">
							<div>{{ nombre(nb_inactifs[m].hommes) }}</div>
							<div>{{ nombre(nb_inactifs[m].femmes) }}</div>
							<div>{{ nombre(nb_inactifs[m].associations) }}</div>
							<div>{{ nombre(nb_inactifs[m].cooperatives) }}</div>
							<div>{{ nombre(nb_inactifs[m].entreprises) }}</div>
							<div>{{ nombre(nb_inactifs[m].autres) }}</div>
							<div>{{ nombre(nb_inactifs[m].autres+nb_inactifs[m].femmes+nb_inactifs[m].hommes) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Nombre de compte clients ou membres fermé</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(nb_inactifs)">
							<div>{{ nombre(nb_inactifs[m].hommes) }}</div>
							<div>{{ nombre(nb_inactifs[m].femmes) }}</div>
							<div>{{ nombre(nb_inactifs[m].associations) }}</div>
							<div>{{ nombre(nb_inactifs[m].cooperatives) }}</div>
							<div>{{ nombre(nb_inactifs[m].entreprises) }}</div>
							<div>{{ nombre(nb_inactifs[m].autres) }}</div>
							<div>{{ nombre(nb_inactifs[m].autres+nb_inactifs[m].femmes+nb_inactifs[m].hommes) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Emprunteurs</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(nb_emprunteurs)">
							<div>{{ nombre(nb_emprunteurs[m].hommes) }}</div>
							<div>{{ nombre(nb_emprunteurs[m].femmes) }}</div>
							<div>{{ nombre(nb_emprunteurs[m].associations) }}</div>
							<div>{{ nombre(nb_emprunteurs[m].cooperatives) }}</div>
							<div>{{ nombre(nb_emprunteurs[m].entreprises) }}</div>
							<div>{{ nombre(nb_emprunteurs[m].autres) }}</div>
							<div>{{nombre(nb_emprunteurs[m].autres+nb_emprunteurs[m].femmes+nb_emprunteurs[m].hommes)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Nombre de credits radiés</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(nb_emprunteurs)">
							<div>{{ nombre(0) }}</div>
							<div>{{ nombre(0) }}</div>
							<div>{{ nombre(0) }}</div>
							<div>{{ nombre(0) }}</div>
							<div>{{ nombre(0) }}</div>
							<div>{{ nombre(0) }}</div>
							<div>{{ nombre(0) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Nombre de deposants</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(nb_contribs)">
							<div>{{ nombre(nb_contribs[m].hommes) }}</div>
							<div>{{ nombre(nb_contribs[m].femmes) }}</div>
							<div>{{ nombre(nb_contribs[m].associations) }}</div>
							<div>{{ nombre(nb_contribs[m].cooperatives) }}</div>
							<div>{{ nombre(nb_contribs[m].entreprises) }}</div>
							<div>{{ nombre(nb_contribs[m].autres) }}</div>
							<div>{{nombre(nb_contribs[m].autres+nb_contribs[m].femmes+nb_contribs[m].hommes)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Montant des crédits octroyés</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(credits)">
							<div>{{ money(credits[m].hommes) }}</div>
							<div>{{ money(credits[m].femmes) }}</div>
							<div>{{ money(credits[m].associations) }}</div>
							<div>{{ money(credits[m].cooperatives) }}</div>
							<div>{{ money(credits[m].entreprises) }}</div>
							<div>{{ money(credits[m].autres) }}</div>
							<div>{{money(credits[m].autres+credits[m].femmes+credits[m].hommes)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>En cours total crédit</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(current_credits)">
							<div>{{ nombre(current_credits[m].hommes) }}</div>
							<div>{{ nombre(current_credits[m].femmes) }}</div>
							<div>{{ nombre(current_credits[m].associations) }}</div>
							<div>{{ nombre(current_credits[m].cooperatives) }}</div>
							<div>{{ nombre(current_credits[m].entreprises) }}</div>
							<div>{{ nombre(current_credits[m].autres) }}</div>
							<div>{{nombre(current_credits[m].autres+current_credits[m].femmes+current_credits[m].hommes)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>En cours crédits sains</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(sains)">
							<div>{{ nombre(sains[m].hommes) }}</div>
							<div>{{ nombre(sains[m].femmes) }}</div>
							<div>{{ nombre(sains[m].associations) }}</div>
							<div>{{ nombre(sains[m].cooperatives) }}</div>
							<div>{{ nombre(sains[m].entreprises) }}</div>
							<div>{{ nombre(sains[m].autres) }}</div>
							<div>{{nombre(sains[m].autres+sains[m].femmes+sains[m].hommes)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>En cours crédits en souffrance </b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(souffrances)">
							<div>{{ nombre(souffrances[m].hommes) }}</div>
							<div>{{ nombre(souffrances[m].femmes) }}</div>
							<div>{{ nombre(souffrances[m].associations) }}</div>
							<div>{{ nombre(souffrances[m].cooperatives) }}</div>
							<div>{{ nombre(souffrances[m].entreprises) }}</div>
							<div>{{ nombre(souffrances[m].autres) }}</div>
							<div>{{nombre(souffrances[m].autres+souffrances[m].femmes+souffrances[m].hommes)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Montant de depots collectés</b></div>
							<div>Hommes</div>
							<div>Femmes</div>
							<div>Association</div>
							<div>Coopérative</div>
							<div>Entreprise</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(depots)">
							<div>{{ money(depots[m].hommes) }}</div>
							<div>{{ money(depots[m].femmes) }}</div>
							<div>{{ money(depots[m].associations) }}</div>
							<div>{{ money(depots[m].cooperatives) }}</div>
							<div>{{ money(depots[m].entreprises) }}</div>
							<div>{{ money(depots[m].autres) }}</div>
							<div>{{money(depots[m].autres+depots[m].femmes+depots[m].hommes)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Crédits octroyé par secteur d'activité</b></div>
							<div>Commerce</div>
							<div>Agriculture/Elevage</div>
							<div>Artisanat</div>
							<div>Social</div>
							<div>Habitat</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(secteur_nb_credits)">
							<div>{{ money(secteur_nb_credits[m].commerce) }}</div>
							<div>{{ money(secteur_nb_credits[m].agriculture) }}</div>
							<div>{{ money(secteur_nb_credits[m].artisanat) }}</div>
							<div>{{ money(secteur_nb_credits[m].social) }}</div>
							<div>{{ money(secteur_nb_credits[m].habitat) }}</div>
							<div>{{ money(secteur_nb_credits[m].autres) }}</div>
							<div>{{ money(secteur_nb_credits[m].total) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>Montant crédits radiés</b></div>
							<div>Commerce</div>
							<div>Agriculture/Elevage</div>
							<div>Artisanat</div>
							<div>Social</div>
							<div>Habitat</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(nb_contribs)">
							<div>{{ nombre(0)}}</div>
							<div>{{ nombre(0)}}</div>
							<div>{{ nombre(0)}}</div>
							<div>{{ nombre(0)}}</div>
							<div>{{ nombre(0)}}</div>
							<div>{{ nombre(0)}}</div>
							<div>{{ nombre(0)}}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>En cours total crédit</b></div>
							<div>Commerce</div>
							<div>Agriculture/Elevage</div>
							<div>Artisanat</div>
							<div>Social</div>
							<div>Habitat</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(secteur_credits)">
							<div>{{ money(secteur_credits[m].commerce) }}</div>
							<div>{{ money(secteur_credits[m].agriculture) }}</div>
							<div>{{ money(secteur_credits[m].artisanat) }}</div>
							<div>{{ money(secteur_credits[m].social) }}</div>
							<div>{{ money(secteur_credits[m].habitat) }}</div>
							<div>{{ money(secteur_credits[m].autres) }}</div>
							<div>{{ money(secteur_credits[m].total) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>En cours crédit sains</b></div>
							<div>Commerce</div>
							<div>Agriculture/Elevage</div>
							<div>Artisanat</div>
							<div>Social</div>
							<div>Habitat</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(secteur_credits_sains)">
							<div>{{ money(secteur_credits_sains[m].commerce) }}</div>
							<div>{{ money(secteur_credits_sains[m].agriculture) }}</div>
							<div>{{ money(secteur_credits_sains[m].artisanat) }}</div>
							<div>{{ money(secteur_credits_sains[m].social) }}</div>
							<div>{{ money(secteur_credits_sains[m].habitat) }}</div>
							<div>{{ money(secteur_credits_sains[m].autres) }}</div>
							<div>{{ money(secteur_credits_sains[m].total) }}</div>
						</td>
					</tr>
					<tr>
						<td>
							<div><b>En cours crédit en souffrance</b></div>
							<div>Commerce</div>
							<div>Agriculture/Elevage</div>
							<div>Artisanat</div>
							<div>Social</div>
							<div>Habitat</div>
							<div>Autres</div>
							<div>Total</div>
						</td>
						<td v-for="m in Object.keys(secteur_credits_souffrants)">
							<div>{{ money(secteur_credits_souffrants[m].commerce) }}</div>
							<div>{{ money(secteur_credits_souffrants[m].agriculture) }}</div>
							<div>{{ money(secteur_credits_souffrants[m].artisanat) }}</div>
							<div>{{ money(secteur_credits_souffrants[m].social) }}</div>
							<div>{{ money(secteur_credits_souffrants[m].habitat) }}</div>
							<div>{{ money(secteur_credits_souffrants[m].autres) }}</div>
							<div>{{ money(secteur_credits_souffrants[m].total) }}</div>
						</td>
					</tr>
					<!-- 
					<tr>
						<td>
							<div><b>Tarification des opérations avec les clients</b></div>
							<div>Taux d'interet créditeur minimum sur les depots des membres clients et beneficiaires</div>
							<div>Taux d'interet créditeur maximum sur les depots des membres clients et beneficiaires</div>
							<div>Taux d'interet créditeur nominal debiteur minimum sur les credits accordés au membres, clients et beneficiaires</div>
							<div>Taux d'interet créditeur nominal debiteur maximum sur les credits accordés au membres, clients et beneficiaires</div>
						</td>
					</tr> -->
				</tbody>
				<tfoot>
					<tr>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
import PrintableHeader from "../../components/printable_header"

export default {
	components:{ PrintableHeader },
	data(){
		return {
			year: new Date().getFullYear(),
			nb_membres: {},
			nb_inactifs: {},
			nb_emprunteurs: {},
			nb_radies: {},
			nb_contribs: {},
			credits: {},
			current_credits: {},
			sains: {},
			souffrances: {},
			contribs: {},
			depots: {},
			secteur_nb_credits: {},
			secteur_credits: {},
			secteur_credits_sains: {},
			secteur_credits_souffrants: {},
		}
	},
	computed:{
		years(){
			let list = []
			let year = new Date().getFullYear()
			for(var i=2021; i <= year; i++){
				list.push(i)
			}
			return list
		}
	},
	methods:{
		fetchComptes(){
			let link = this.url+`/rapports/comptes/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.nb_membres = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchComptes)
			}).finally(() => {
				this.fetchInactifs()
			})
		},
		fetchInactifs(){
			let link = this.url+`/rapports/nb_inactifs/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.nb_inactifs = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchInactifs)
			}).finally(() => {
				this.fetchEmprunteurs()
			})
		},
		fetchEmprunteurs(){
			let link = this.url+`/rapports/nb_emprunteurs/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.nb_emprunteurs = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchEmprunteurs)
			}).finally(() => {
				this.fetchContribs()
			})
		},
		fetchContribs(){
			let link = this.url+`/rapports/nb_contribs/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.nb_contribs = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchContribs)
			}).finally(() => {
				this.fetchCredits()
			})
		},
		fetchCredits(){
			let link = this.url+`/rapports/credits/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.credits = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchCredits)
			}).finally(() => {
				this.fetchCurrentCredits()
			})
		},
		fetchCurrentCredits(){
			let link = this.url+`/rapports/current_credits/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.current_credits = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchCurrentCredits)
			}).finally(() => {
				this.fetchSains()
			})
		},
		fetchSains(){
			let link = this.url+`/rapports/sains/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.sains = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchSains)
			}).finally(() => {
				this.fetchSouffrant()
			})
		},
		fetchSouffrant(){
			let link = this.url+`/rapports/souffrances/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.souffrances = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchSouffrant)
			}).finally(() => {
				this.fetchDepots()
			})
		},
		fetchDepots(){
			let link = this.url+`/rapports/depots/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.depots = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchDepots)
			}).finally(() => {
				this.fetchNbSecteurCredits()
			})
		},
		fetchNbSecteurCredits(){
			let link = this.url+`/rapports/secteur_nb_credits/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.secteur_nb_credits = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchNbSecteurCredits)
			}).finally(() => {
				this.fetchSecteurCredits()
			})
		},
		fetchSecteurCredits(){
			let link = this.url+`/rapports/secteur_credits/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.secteur_credits = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchSecteurCredits)
			}).finally(() => {
				this.fetchSecteurCreditsSains()
			})
		},
		fetchSecteurCreditsSains(){
			let link = this.url+`/rapports/secteur_credits_sains/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.secteur_credits_sains = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchSecteurCreditsSains)
			}).finally(() => {
				this.fetchSecteurCreditsSouffrants()
			})
		},
		fetchSecteurCreditsSouffrants(){
			let link = this.url+`/rapports/secteur_credits_souffrants/year/${this.year}/`
			axios.get(link, this.headers)
			.then((response) => {
				this.secteur_credits_souffrants = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchSecteurCreditsSouffrants)
			}).finally(() => {
				// this.fetchSecteurCredits()
			})
		},
		imprimer(){
			print()
		}
	},
	mounted(){
		this.fetchComptes()
	}
};
</script>
<style scoped>
.table>table,th,td {
	border-collapse: collapse;
}
.container {
	min-height: calc(100vh - 180px);
}
.btn-sm{
	margin-bottom: 0;
}
.buttons{
	display: flex;
	align-items: flex-end;
	margin-bottom: 0;
}
.field label{
	color: var(--primary);
	font-weight: 700;
}
.field{
	padding: 0 10px 0 0;
	margin-bottom: 0;
}
.left{
	display: flex;
	align-items: flex-end;
}
td{
	font-weight: inherit;
	vertical-align: bottom;
}
@media screen and (max-width:600px) {
	.container {
		padding: 0 20px;
	}
	.left{
		flex-direction: column;
	}
}
</style>
