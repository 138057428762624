<template>
  <div class="container">
    <PrintableHeader :intervalle="intervalle_date" :client="compte" />
    <div class="row nonprintable">
      <div class="left">
        <div class="field">
          <label for="du">Du: </label>
          <input type="date" id="du" v-model="du" />
        </div>
        <div class="field">
          <label for="au">Au: </label>
          <input type="date" id="au" v-model="au" />
        </div>
        <button class="btn-sm bg-primary" @click="filterDate">Filtrer</button>
        <button class="btn-sm bg-primary" @click="imprimer">Imprimer</button>
      </div>
      <div class="search-area">
        <input type="text" v-on:keyup.enter="rechercher" placeholder="rechercher" v-model="keyword">
        <button class="search" @click="rechercher">Rechercher</button>
      </div>
    </div>
    <div class="table" :class="{ nonprintable: !can_print }">
      <table>
        <thead>
          <tr>
            <th>Heure</th>
            <th>debit</th>
            <th>credit</th>
            <th>solde</th>
            <th v-if="compte.en_epargne">Epargne</th>
            <th>action</th>
            <th v-if="motif">motif</th>
            <th class="nonprintable">acteur</th>
            <!-- <th class="nonprintable" v-if="active_user_is('cto', 'admin')">
              action
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in history">
            <td>{{ datetime(record.date) }}</td>
            <td class="money">{{ money(debit(record)) }}</td>
            <td class="money">{{ money(credit(record)) }}</td>
            <td class="money">{{ money(solde(record)) }}</td>
            <td v-if="compte.en_epargne" class="money">
              {{ money(creditEp(record)) }}
            </td>
            <td>{{ record.details }}</td>
            <td v-if="motif">{{ record.action }}</td>
            <td class="nonprintable">
              {{ record.user.first_name }} {{ record.user.last_name }}
            </td>
            <!-- <td class="nonprintable" v-if="active_user_is('cto', 'admin')">
              <button
                class="btn-sm bg-danger"
                @click="deleteHistory(record.id)"
              >
                <fa icon="trash" />
              </button>
              <button class="btn-sm bg-primary" @click="modifyHistory(record)">
                <fa icon="pen" />
              </button>
              <button class="btn-sm bg-danger" @click="createHistory(record)">
                <fa icon="pen" />
              </button>
            </td> -->
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="center">Total débits :</th>
            <th class="money">
              {{ money(history.reduce((acc, x) => (acc += debit(x)), 0)) }}
            </th>
          </tr>
          <tr>
            <th class="center">Total crédits :</th>
            <th class="money">
              {{ money(history.reduce((acc, x) => (acc += credit(x)), 0)) }}
            </th>
          </tr>
          <tr>
            <th class="center">Solde Courant :</th>
            <th class="money">
              {{
                money(
                  history.reduce((acc, x) => (acc += credit(x)), 0) -
                    history.reduce((acc, x) => (acc += debit(x)), 0)
                )
              }}
            </th>
          </tr>
          <tr v-if="compte.en_epargne">
            <th class="center">Solde Epargne :</th>
            <th class="money">
              {{ money(history.reduce((acc, x) => (acc += creditEp(x)), 0)) }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <ModifyHistory
      v-if="modal"
      :histProp="histObj"
      @close="modal = false"
    ></ModifyHistory>
    <CreateHistory
      v-if="create_hist_shown"
      @close="create_hist_shown = false"
    ></CreateHistory>
    <PrintableFooter />
  </div>
</template>
<script>
import PrintableHeader from "@/components/printable_header";
import PrintableFooter from "@/components/printable_footer";
import ModifyHistory from "@/components/dialog_history";
import CreateHistory from "@/components/cto/dialog_create_hist";

export default {
  components: {
    PrintableHeader,
    ModifyHistory,
    PrintableFooter,
    CreateHistory,
  },
  data() {
    return {
      du: null,
      au: null,
      modal: false,
      create_hist_shown: false,
      histObj: null,
      can_print: false,
      history: [],
      compte: {},
      intervalle_date: null,
      motif: false,
      next: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    can_print(new_val) {
      if (new_val) {
        setTimeout(() => print(), 100);
      }
    },
  },
  methods: {
    solde(record) {
      if (record.action != "Depot Epargne") {
        return record.balance_compte_apres;
      }
      return 0;
    },
    debit(record) {
      if (record.action != "Depot Epargne") {
        if (record.balance_compte_avant > record.balance_compte_apres) {
          return record.balance_compte_avant - record.balance_compte_apres;
        }
      }
      return 0;
    },
    credit(record) {
      if (record.action != "Depot Epargne") {
        if (record.balance_compte_avant < record.balance_compte_apres) {
          return record.balance_compte_apres - record.balance_compte_avant;
        }
      }
      return 0;
    },
    creditEp(record) {
      if (
        record.action == "Depot Epargne" &&
        record.balance_compte_avant >= 0
      ) {
        if (record.balance_compte_avant < record.balance_compte_apres) {
          return record.balance_compte_apres - record.balance_compte_avant;
        }
      }
      return 0;
    },
    fetchHistory() {
      axios
        .get(this.url + `/compte/${this.id}/historique/`, this.headers)
        .then((response) => {
          this.history = response.data.historique;
          this.orderBy("date");
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchHistory);
        })
        .finally(() => {
          this.getCompte(this.id);
        });
    },
    compareStings(a, b, order) {
      if (a[order] > b[order]) {
        return 1;
      } else if (a[order] == b[order]) {
        return 0;
      } else {
        return -1;
      }
    },
    orderBy(order) {
      this.history.sort((a, b) => {
        return typeof a[order] == "string"
          ? this.compareStings(a, b, order)
          : a[order] - b[order];
      });
    },
    filterDate() {
      this.intervalle_date = { du: this.du, au: this.au };
      this.headers["params"] = {
        compte: `${this.id}`,
        date__gte: `${this.du}T00:00`,
        date__lte: `${this.au}T23:59`,
      }
      let link;
      if (!this.next) {
        this.history = [];
        link = this.url + `/historique/`;
      } else {
        link = this.next;
      }
      axios
        .get(link, this.headers)
        .then((response) => {
          this.history.push(...response.data.results);
          if (response.data.next != null) {
            this.next = response.data.next;
            this.filterDate();
          } else {
            this.next = null;
          }
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.filterDate);
        });
    },
    modifyHistory(hist) {
      this.modal = true;
      this.histObj = hist;
    },
    createHistory() {
      this.modal = false;
      this.create_hist_shown = true;
    },
    getCompte(id) {
      axios
        .get(this.url + `/compte/${id}/`, this.headers)
        .then((response) => {
          this.compte = response.data;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.getCompte);
        });
    },
    imprimer() {
      if (this.active_user_is("readonly")) {
        this.$store.state.alert = {
          type: "danger",
          message: "Vous êtes en lecture seule",
        };
        return;
      }
      if (this.can_print) {
        print();
      } else {
        this.headers["params"] = {
          date__gte: `${this.du}T00:00`,
          date__lte: `${this.au}T23:59`,
        }
        axios .get(this.url + `/compte/${this.id}/demande_impression/`, this.headers)
        .then((response) => {
          this.history.push(response.data);
          this.can_print = true;
        })
        .catch((error) => {
          alert(this.cleanString(error.response.data));
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.history.length == 0) {
      this.fetchHistory();
    }
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
}
.container {
  min-height: calc(100vh - 130px);
}
.row {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  justify-items: flex-end;
}
.left {
  display: flex;
  align-items: flex-end;
}
input[type="date"],
select {
  width: 200px;
  outline: none;
  border: 2px solid var(--primary);
}
.field label {
  color: var(--primary);
  font-weight: 700;
}
.field {
  padding: 0 10px 0 0;
  margin-bottom: 0;
}
.buttons {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}
.btn-sm {
  margin: 0 5px;
}
@media screen and (max-width: 800px) {
  .row {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
  }
}
.money {
  white-space: nowrap;
}
@media screen and (max-width: 600px) {
  .container {
    padding: 0 20px;
  }
  .row {
    margin: 10px auto;
    display: block;
    width: 100%;
    height: auto;
  }
}
@media print {
  .table table{
    max-width: 90vw;
    margin: auto
  }
}
</style>
