import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    api: "/api",
    groups: [],
    users: [],
    agences: [],
    comptes: [],
    membres: [],
    home_infos: { historique: [], },
    credits_overview: {},
    credits: [],
    placements: [],
    journals: [],
    history: [],
    cheques: [],
    print_cheques: [],

    produits: [],
    charges: [],
    investissements: [],
    passifs: [],
    autres: [],

    ibirimba: [],
    type_depenses: {},
    depots_kirimba: [],
    mainHistory:{},
    PRODUITS: [],
    CHARGES: [],
    INVESTISSEMENTS: [],
    PASSIFS_ET_FONDS: [],
    alert: { type: "", message: "Bienvenue" },
    current_charge: null,
    PLAN_COMPTABLE: {},
  },
  mutations: {},
  actions: {},
  modules: {},
});
