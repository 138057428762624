<template>
	<div class="container">
    <PrintableHeader/>
		<div class="row nonprintable">
			<div class="extra">
				<button class="btn" @click="cheques = $store.state.cheques">
					Tout
				</button>
				<button class="btn" v-if="active_user.is_admin" @click="generateCheque">
					Generer les Commandes
				</button>
				<button class="btn" @click="fetchCommandes">
					Commandes
				</button>
				<button v-if="commandes" class="btn" @click="openPrintCheques">
					Imprimer
				</button>
			</div>
			<div class="search-area">
				<input type="text" v-on:keyup.enter="rechercher" placeholder="rechercher" v-model="keyword">
				<button class="search" @click="rechercher">Rechercher</button>
			</div>
		</div>
		<div class="table">
			<table> 
				<thead>
					<tr>
						<th v-if="commandes">
							<input type="checkbox" v-model="select_all">
						</th>
						<th>No. cheque</th>
						<th>libellé</th>
						<th>En cours</th>
						<th class="nonprintable">Restant</th>
						<th>En attente</th>
						<th>Nb Cheques</th>
						<th>Demandé</th>
						<th class="nonprintable">Options</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="cheque in cheques" :id="'active-row-'+cheque.id">
						<th v-if="commandes">
							<input type="checkbox" v-model="cheque.selected" >
						</th>
						<td>{{ cheque.compte.account_number }}</td>
						<td>{{ cheque.compte.first_name }} {{ cheque.compte.last_name }}</td>
						<td>{{ cheque.current }}</td>
						<td class="nonprintable">
							<span v-if="!!cheque.remaining_numbers">
								{{ cheque.remaining_numbers.slice(0, 30) }}
								<span v-if="cheque.remaining_numbers.length>30">...</span>
							</span>
						</td>
						<td>{{ cheque.waiting }}</td>
						<td>{{ !!cheque.waiting?cheque.asked_number:'-' }}</td>
						<td>{{ cheque.asked ? "OUI" : "NON" }}</td>
						<td class="nonprintable">
							<div v-if="!spinner">
								<button  :id="'commander-'+cheque.id" class="btn-sm bg-primary" v-if="!cheque.asked && !cheque.waiting"
									@click.stop="hideOrShowHTMLElements(cheque.id)">
								Commander
								</button>
								<div :id="'cheque-'+cheque.id"  class="asked-number hide" >
									<input type="text" focus placeholder="entre le nombre de cheques" v-model="cheque.asked_number">
									<div>
										<button class="btn-sm bg-primary" @click="askCheque(cheque)">Commander</button>
										<button class="btn-sm bg-danger" @click="hideOrShowHTMLElements(cheque.id)">Annuler</button>									
									</div>
								</div>
								<span v-if="active_user_is('responsable', 'admin') && !!cheque.waiting">
									<button class="btn-sm bg-success" @click.stop="affecterCheque(cheque)">
										Affecter
									</button>
								</span>
								<span v-if="active_user_is('cto', 'admin') && !cheque.waiting">
									<button class="btn-sm bg-danger" @click.stop="undoCheque(cheque)">
										Undo
									</button>
									<button class="btn-sm bg-secondary" @click="printOneCheque(cheque)">Réimprimer</button>									
								</span>
							</div>
							<div v-else >Soumission en cours...</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import PrintableHeader from "@/components/printable_header"

export default {
  props: {},
  components:{ PrintableHeader },
  data(){
  	return {
  		cheques: this.$store.state.cheques,
		keyword:"",
		spinner:false,
		asked_number:20,
		commandes:false,
		select_all: false
  	}
  },
  watch:{
  	"$store.state.cheques"(new_val){
  		this.cheques = new_val;
  	},
  	keyword(new_val){
  		this.cheques = this.$store.state.cheques.filter( x => {
  		   return  JSON.stringify(x).toLowerCase().includes(new_val.toLowerCase())
		})
  	},
	select_all(new_val){
		this.cheques.forEach(x => x.selected = new_val)
	}
  },
  methods:{
  	askCheque(cheque){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      if(!cheque.asked_number){
      	this.$store.state.alert = {
          type:"danger",
          message:"Veuillez spécifier le nombre de cheques !"
        }
        return
      }
      this.spinner=true
  		if(confirm(`Voulez-vous passer une commande pour ${cheque.compte.first_name} ${cheque.compte.last_name}?`)){
				axios.get(this.url+`/cheque/${cheque.id}/commander/${cheque.asked_number}/`,this.headers)
				.then((response) => {
					cheque.asked = true
					this.hideOrShowHTMLElements(cheque.id)
				}).catch((error) => {
					this.displayErrorOrRefreshToken(error, this.askCheque)
				}).finally(()=>{
					this.spinner=false
				})
  		}
  	},
  	generateCheque(cheque){
		if(this.active_user_is('readonly')){
			this.$store.state.alert = {
				type:"danger",
				message:"Vous êtes en lecture seule"
			}
			return
		}
		axios.get(this.url+`/cheque/generate/`, this.headers)
		.then((response) => {
			this.cheques = response.data
			alert(`${response.data.length} ont été génerée avec success`);
		}).catch((error) => {
			this.displayErrorOrRefreshToken(error, this.generateCheque)
		})
  	},
  	affecterCheque(cheque){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
  		if(confirm(`Voulez remettre à ${cheque.compte.first_name} ${cheque.compte.last_name} son carnet?`)){
			axios.get(this.url+`/cheque/${cheque.id}/remettre/`, this.headers)
			.then((response) => {
				alert("success")
				this.cheques[this.cheques.indexOf(cheque)] = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.affecterCheque)
			})
  		}
  	},
  	undoCheque(cheque){
		if(this.active_user_is('readonly')){
			this.$store.state.alert = {
				type:"danger",
				message:"Vous êtes en lecture seule"
			}
			return
		}
  		if(confirm(`Voulez-vous effectuer cet action ? Details : ${cheque.compte.first_name} ${cheque.compte.last_name} `)){
			axios.get(this.url+`/cheque/${cheque.id}/undo/`, this.headers)
			.then((response) => {
				alert("success")
				this.cheques[this.cheques.indexOf(cheque)] = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.undoCheque)
			})
  		}
  	},
  	rechercher(){
		axios.get(this.url+`/cheque/?search=${this.keyword}`, this.headers)
		.then((response) => {
			this.$store.state.cheques = response.data.results
		}).catch((error) => {
			this.displayErrorOrRefreshToken(error, this.rechercher)
		})
  	},
    fetchCheques(){
    	this.commandes=false
			axios.get(this.url+"/cheque/", this.headers)
			.then((response) => {
				this.$store.state.cheques = response.data.results
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchCheques)
			})
    },
    hideOrShowHTMLElements(chequeId){
        let chequier = document.getElementById('cheque-'+chequeId)
        let commander = document.getElementById('commander-'+chequeId)
        let active = document.getElementById('active-row-'+chequeId)
        chequier.classList.toggle('hide')
        commander.classList.toggle('hide')
        active.classList.toggle('active-row')
    },
    fetchCommandes(){
		axios.get(this.url+"/cheque/commandes/", this.headers)
		.then((response) => {
			this.commandes=true
			let data = response.data
			data.forEach(x => x.selected = false)
			this.cheques = data
		}).catch((error) => {
			this.displayErrorOrRefreshToken(error, this.fetchCommandes)
		})
    },
    openPrintCheques(){
		this.$store.state.print_cheques = this.cheques.filter(x => x.selected)
		this.$router.push("/print-cheques")
    },
    printOneCheque(cheque){
		cheque.waiting = cheque.current
		this.$store.state.print_cheques = [ cheque ]
		this.$router.push("/print-cheques")
    }
  },
  mounted(){
  	if(this.active_user_is('guichetier'))
  		this.$router.push("/")
    if(this.$store.state.cheques.length<2){
      this.fetchCheques()
    }
  }
};
</script>
<style scoped>
.container {
  min-height: calc(100vh - 130px);
}
.asked-number{
	display:flex;
}
.hide{
	display:none;
}
.asked-number>input{
	max-width:20%;
}
.asked-number>div{
	max-width:80%;
	margin-left:8px;
}
.active-row{
	background-color: #79b;
}
.extra{
	display:flex;
	justify-content:space-between;
}
</style>
