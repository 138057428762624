<template>
  <div class="table">
    <div class="head">
      <h1>Plan Comptable</h1>
      <div class="form">
        <button @click="updatePlan()" class="btn-sm bg-primary">
          Génerer Un Plan
        </button>
      </div>
    </div>
    <div class="page">
      <div
        v-for="item in $store.state.PLAN_COMPTABLE"
        class="item"
        :class="'niveau_' + uburebure(item.numero)">
        <div class="numero">{{ item.id }}</div>
        <div class="nom">{{ item.nom }}</div>
        <button class=btn @click="$router.push(`/activite-comptable/${item.numero}`)">
          Journal
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      plan_comptables: [],
      date: new Date(),
    };
  },

  methods: {
    uburebure(numero) {
      return numero.length < 5 ? numero.length : 5;
    },
    fetchPlan() {
      axios
        .get(this.url + "/plan_comptable/", this.headers)
        .then((res) => {
          this.$store.state.PLAN_COMPTABLE = res.data;
        })
        .catch((err) => {
          this.displayErrorOrRefreshToken(err, this.fetchPlan);
        });
    },
    updatePlan() {
      let url = this.url + "/plan_comptable/update-plan-comptable/";
      axios.get(url, this.headers).then((res) => {
        this.plan_comptables = res.data;
        this.$store.state.PLAN_COMPTABLE = res.data;
      });
    },
  },
  mounted() {
    this.fetchPlan();
    console.log(this.plan_comptable);
    this.updatePlan();
  },
};
</script>
<style scoped>
.page {
  background-color: #fff9;
  padding: 10px;
}
.item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.item:hover{
  background-color: #bbb;
}
.niveau_1 {
  font-weight: 700;
  margin: 10px 0;
}
.niveau_2 {
  margin: 5px 0;
}
.niveau_3 {
  margin: 5px 0 0 30px;
}
.niveau_4 {
  margin-left: 60px;
}
.niveau_5 {
  margin-left: 90px;
}
.info {
  display: flex;
  flex-direction: column;
}
.form {
  justify-content: flex-end;
  display: flex;
  padding-bottom: 10px;
  gap: 10px;
}
.table {
  width: 90%;
}
.btn{
  margin: 2px 0 2px auto;
  padding: 2px 5px;
}
</style>
