<template>
  <Overlay @close="$emit('close')">
    <PrintableHeader/>
    <div class="table">
      <h1 class="printable">
        Cloture du placement
      </h1>
      <table>
        <tbody>
          <tr>
            <td>Intérêts déjà reçu</td>
            <td>:</td>
            <td>{{ money(detailsProps?.interets_recus) }}</td>
          </tr>
          <tr>
            <td>Totals jours en cours</td>
            <td>:</td>
            <td>{{ money(detailsProps?.jours_en_cours) }}</td>
          </tr>
          <tr>
            <td>Jours considerés</td>
            <td>:</td>
            <td>{{ money(detailsProps?.jours_considere) }}</td>
          </tr>
          <tr>
            <td>Interets à recevoir</td>
            <td>:</td>
            <td>{{ money(detailsProps?.interets_en_cours) }}</td>
          </tr>
          <tr>
            <td>Capital</td>
            <td>:</td>
            <td>{{ money(detailsProps?.capital) }}</td>
          </tr>
          <tr>
            <td>Capital + Interets à recevoir</td>
            <td>:</td>
            <td>{{ money(detailsProps?.capital+detailsProps?.interets_en_cours) }}</td>
          </tr>
        </tbody>
      </table>
    <button class="btn" @click="cloturerPlacement">
      Valider cloture placement
    </button>
    </div>
  </Overlay>
</template>
<script>
import Overlay from "./dialog_overlay"
import PrintableHeader from "./printable_header"
export default {
  components:{Overlay,PrintableHeader},
  props:["detailsProps","placementProps"],
  data(){
    return {
      montant:0,
      icome_type:0,
      type_depense:"",
      date:"",
      logs:""
    }
  },
  methods:{
    cloturerPlacement(){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      let data = this.detailsProps
      let confirm = this.active_user.first_name+" "+this.active_user.last_name;
      if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
        axios.post(this.url+`/placement/${this.placementProps}/cloturer-pl/`,data, this.headers)
        .then((response) => {
          alert("Action reussi !")
          this.$emit('close')
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.cloturerPlacement)
        });
      } else {
        alert("ABANDONÉE")
      }
    }
  }
};
</script>
<style scoped>
.content{
  max-width: 600px !important;
}
</style>
