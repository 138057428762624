<template>
  <div class="container">
    <PrintableHeader />
    <div class="row">
      <div class="first">
        <table class="amort-table">
          <tbody>
            <tr>
              <td class="identifier">Compte :</td>
              <td>{{ !!credit.account ? credit.account.account_number : "" }}</td>
            </tr>
            <tr>
              <td class="identifier">Nom :</td>
              <td>{{ !!credit.account ? credit.account.first_name : "" }}</td>
            </tr>
            <tr>
              <td class="identifier">Prenom :</td>
              <td>{{ !!credit.account ? credit.account.last_name : "" }}</td>
            </tr>
            <tr>
              <td class="identifier">CNI :</td>
              <td>{{ !!credit.account ? credit.account.CNI : "" }}</td>
            </tr>
            <tr>
              <td class="identifier">Numero de Telephone :</td>
              <td>{{ !!credit.account ? credit.account.phone_number : "" }}</td>
            </tr>
            <tr>
              <td class="identifier">Adresse :</td>
              <td>{{ !!credit.account ? credit.account.adress : "" }}</td>
            </tr>
            <tr>
              <td class="identifier">Personne de confiance :</td>
              <td>{{ !!credit.account ? credit.account.confiance : "" }}</td>
            </tr>
            <tr>
              <td class="identifier">CNI de la personne :</td>
              <td>
                {{ !!credit.account ? credit.account.CNI_confiance : "" }}
              </td>
            </tr>
            <tr>
              <td class="identifier">Credit Restant Dû :</td>
              <td class="logs">
                <h3>{{ money(credit.restant) }} FBU</h3>
              </td>
            </tr>
            <tr>
              <td class="identifier">Capital Restant Dû :</td>
              <td class="logs">
                <h3>{{ money(capital_restant_du) }} FBU</h3>
              </td>
            </tr>
            <!-- <tr>
              <td class="identifier">Capital deja remboursé :</td>
              <td class="logs">
                <h3>{{ money(capital_deja_remboursse >= 0 ? capital_deja_remboursse : 0) }} FBU</h3>
              </td>
            </tr> -->
            <tr v-if="!credit.approved_by">
              <td class="identifier">Type Crédit</td>
              <td>
                <select v-model="type_credit">
                  <option value="0">linéaire</option>
                  <option value="1">dégressive</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>Echéance</th>
            <th>Restant Dû</th>
            <th>Interêt</th>
            <th>Mensualité</th>
            <th>Reste</th>
            <th>Date</th>
            <th class="nonprintable">Penalités</th>
            <th>Payée</th>
            <th>options</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="line in credit.amortissements">
            <td>{{ money(line.echeance) }}</td>
            <td>{{ money(line.rest) }}</td>
            <td>{{ money(line.interest) }}</td>
            <td>{{ money(line.initial_mensuality) }}</td>
            <td>{{ money(line.mensuality) }}</td>
            <td>{{ line.date }}</td>
            <td class="nonprintable">{{ line.retard }}</td>
            <td>{{ line.done ? "OUI" : "NON" }}</td>
            <td>
              <button @click="reculer(line)" v-if="line.initial_mensuality <= line.mensuality"
                class="btn-sm bg-primary">
                Reculer
              </button>
              <button @click="anticiper(line.credit)" v-if="line.initial_mensuality <= line.mensuality"
                class="btn-sm bg-primary">
                Anticiper
              </button>
              <button v-else class="btn-sm bg-danger" @click="annuler(line)">
                Annuler
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="noms printable">
        <div class="client">
          <span>
            {{ !!credit.account ? credit.account.first_name : "" }}
          </span>
          <span>
            {{ !!credit.account ? credit.account.last_name : "" }}
          </span>
        </div>
        <div>ISIGI MICROFINANCE</div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintableHeader from "@/components/printable_header";

export default {
  components: { PrintableHeader },
  props: {},
  data() {
    return {
      credit_id: 0,
      type_credit: "",
      credit: {},
      capital_restant_du: null,
      capital_deja_remboursse: null
    };
  },
  watch: {
    type_credit(new_val) {
      this.fetchAmortissement(new_val);
    },
    credit: {
      deep: true,
      handler(new_val) {
        let interet_payees = this.credit.amortissements.filter(credit => credit.done).reduce(
          (acc, x) => (acc += parseFloat(x.interest)),
          0
        );
        let mensualites_payees = this.credit.amortissements.filter(credit => credit.done).reduce(
          (acc, x) => (acc += parseFloat(x.initial_mensuality)),
          0
        );
        let mensualite_cle = this.credit.amortissements.filter(credit => !credit.done)[0]
        this.$nextTick(() => {
          let mensualite_deja_remboursse = mensualites_payees + (mensualite_cle.initial_mensuality || 0) - (mensualite_cle.mensuality || 0)
          this.capital_deja_remboursse = mensualite_deja_remboursse - (mensualite_cle.interest || 0) - interet_payees
          this.capital_restant_du = this.credit.asked_amount - (this.capital_deja_remboursse > 0 ? this.capital_deja_remboursse : 0)
        })
      }
    }
  },
  methods: {
    // modifier(line){
    //   this.active_line = line
    //   this.dialog_shown = true
    // },
    fetchAmortissement(type_credit) {
      let route;
      if (type_credit == 0) {
        route = "amortissement_lineaire/?credit=";
      } else if (type_credit == 1) {
        route = "amortissement_degressive/?credit=";
      }
      axios
        .get(this.url + `/${route}${this.credit_id}`, this.headers)
        .then((response) => {
          this.credit.amortissements = response.data.results;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchAmortissement);
        });
    },
    fetchValidatedCredit(id) {
      axios.get(this.url + `/credit/${id}/`, this.headers)
        .then((response) => {
          this.credit = response.data
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, () => this.fetchValidatedCredit(id))
        })
    },
    reculer(amortissement) {
      let days = prompt(`veuiller taper le nombre des jours`)
      if (days) {
        let data = { days: days }
        axios.post(this.url + `/amortissement/${amortissement.id}/reculer/`, data, this.headers)
          .then((response) => {
            for (let key of Object.keys(response.data)) {
              amortissement[key] = response.data[key]
            }
          }).catch((error) => {
            this.displayErrorOrRefreshToken(error, () => this.reculer(amortissement))
          })
      } else {
        alert("ABANDONÉE")
      }
    },
    anticiper(credit_id) {
      let nom = prompt(`veuiller taper le nom`)
      if (nom) {
        axios.get(this.url + `/credit/${credit_id}/payer-anticipation/`, this.headers)
          .then((response) => {
            console.log(response)
            this.fetchValidatedCredit(this.credit_id);
          }).catch((error) => {
            this.displayErrorOrRefreshToken(error, () => this.anticiper(credit_id))
          })
      } else {
        alert("ABANDONÉE")
      }
    },
    annuler(amortissement) {
      let confirm = this.active_user.first_name + " " + this.active_user.last_name;
      if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
        axios.get(this.url + `/amortissement/${amortissement.id}/annuler/`, this.headers)
          .then((response) => {
            for (let key of Object.keys(response.data)) {
              amortissement[key] = response.data[key]
            }
          }).catch((error) => {
            this.displayErrorOrRefreshToken(error, () => this.annuler(amortissement))
          })
      } else {
        alert("ABANDONÉE")
      }
    }
  },
  mounted() {
    this.credit_id = this.$route.params.id;
    this.fetchValidatedCredit(this.credit_id);
  },
};
</script>
<style scoped>
.info {
  display: inline-block;
  padding: 15px 25px;
  line-height: 1.7;
  background: var(--primary-transparent);
  border-radius: 10px;
}

.desc {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
}

.amount {
  font-size: 18px;
}

.row {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: 30px auto;
}

.first {
  background: #f5f5f5;
  color: #000;
}

.profile-info {
  margin-bottom: 10px;
}

.identifier {
  font-weight: 600;
}

.noms {
  margin-top: 20px;
  display: none;
  justify-content: space-between;
  font-weight: 700;
}

@media print {
  .noms {
    display: flex;
  }
}

@media screen and (max-width: 800px) {
  .row {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    min-height: 180px;
  }

  .info {
    width: 48%;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding: 0 20px;
  }

  .info {
    display: block;
    margin-bottom: 15px;
    width: 100%;
  }

  .desc {
    font-size: 16px;
    font-weight: 600;
  }

  .amount {
    font-size: 15px;
  }

  .row {
    margin: 20px auto;
    display: block;
    width: 100%;
    height: auto;
  }
}

.penalites {
  background: red;
  color: white;
  border: 0;
  padding: 0px 5px 2px 5px;
}

.penalites:hover {
  background: orange;
  border: 1px solid black;
}
</style>
