<template>
	<div class="container">
		<div class="row">
			<div @click="indiv_shown=true" class="add">
				Nouveau individu
			</div>
			<div @click="org_shown=true" class="add">
				Nouvelle organisation
			</div>
			<div v-if="active_user_is('readonly')" @click="getSousComptes" class="add">
				<p>Afficher</p>
				<p>Sous-comptes</p>
			</div>
			<div v-if="active_user_is('readonly')" @click="getComptesEpargne" class="add">
				<p>Comptes Epargnes</p>
			</div>
			<div v-if="active_user_is('cto', 'admin')" @click="getComptesInIsigiInfo" class="add">
				<p>Isigi-Info</p>
			</div>
		</div>
		<div class="table">
			<div class="search-area">
				<input type="text" v-on:keyup.enter="rechercher" placeholder="rechercher" v-model="keyword">
				<button class="search" @click="rechercher">Rechercher</button>
			</div>
			<table>
				<thead>
					<tr>
						<th>#</th>
						<th>No. compte</th>
						<th>Nom & Prenom</th>
						<th>Sexe</th>
						<th>CNI</th>
						<th>Somme</th>
						<th>Epargne</th>
						<th>Options</th>
					</tr>
				</thead>
				<tbody>
					<tr
						:class="{'disabled': !compte.is_active}"
						v-for="compte,i in comptes"
						@click="e => activateRow(e)"
						@dblclick="openProfile(compte.id)">
						<td>{{ i }}</td>
						<td>{{ compte.account_number }}</td>
						<td style="max-width:150px">
							{{ compte.last_name }} {{ compte.first_name }}
						</td>
						<td>
							{{ getSexe(compte) }} 
						</td>
						<td>{{ compte.CNI }}</td>
						<td>{{ !!compte.balance?money(compte.balance):"-" }}</td>
						<td>{{ !!compte.balance_epargne?money(compte.balance_epargne):"-" }}</td>
						<td>
							<router-link :to="'/history-compte/'+compte.id" v-slot="{ navigate }">
								<button class="btn-sm bg-secondary" @click.stop="navigate">
									<fa icon="list-alt"/>
								</button>
							</router-link>
							<span v-if="compte.is_active">
								<button class="btn-sm bg-primary"
									@click.stop="dupliquer(compte)">
									x2
								</button>
<!-- 								<button class="btn-sm bg-primary"
									@click.stop="addCheques(compte)">
									AD
								</button> -->
								<button v-if="active_user_is('cto', 'responsable')" class="btn-sm bg-danger"
									@click.stop="removeCompte(compte)">
									<fa icon="trash"/>
								</button>
							</span>
								<!-- <button v-if="active_user_is('cto')" class="btn-sm bg-danger"
									@click.stop="changeBalance(compte)">
									<fa icon="pen"/>
								</button> -->
								<button class="btn-sm bg-primary"
									@click.stop="editCustomer(compte)"
									v-if="active_user_is('responsable')">
									<fa icon="pen"/>
								</button>
							<span>
								<button class="btn-sm bg-success" v-if="compte.unlock_type==-1"
									@click.stop="unlockCompte(compte)">
									<fa icon="unlock"/>
								</button>
								<button class="btn-sm bg-danger" v-else
									@click.stop="lockCompte(compte)">
									<fa icon="lock"/>
								</button>
								<span v-if="active_user_is('responsable', 'cto')">
									<span v-if="!compte.en_epargne" style="margin-right: 5px;">
										<button class="btn-sm bg-success"
											@click.stop="epargner(compte)">
											epargner
										</button>
									</span>
									<span v-else>
										<button class="btn-sm bg-primary"
											@click.stop="liberer(compte)">
											liberer
										</button>
										<button class="btn-sm bg-primary"
											@click.stop="$router.push('/epargne-list/'+compte.id)">
											état
										</button>
									</span>
								</span>
								<button v-if="endEpargne(compte)" class="btn-sm bg-danger">*
								</button>
							</span>
						</td>
					</tr>
				</tbody>
				<tfoot>
						<tr>
							<td colspan="5"></td>
							<td >{{money(
								comptes.reduce((somme,compte)=>
									somme+=(compte.balance?compte.balance:0)
								,0))
							}}</td>
							<td >{{money(
								comptes.reduce((somme,compte)=>
									somme+=(compte.balance_epargne?compte.balance_epargne:0)
								,0))
							}}</td>
							<td colspan="2"></td>
						</tr>
					</tfoot>
			</table>
			<a href="#" style="color: lightgray;" @click="loadMore" v-if="!!next">
				gukwega ibindi...
			</a>
		</div>
		<DialogCustomer @close="close" :item="active_compte"
			:class="{'hidden':!indiv_shown}"/>
		<DialogOrganisation @close="close" :item="active_compte"
			:class="{'hidden':!org_shown}"/>
		<DialogEpargner @close="close" :item="active_compte"
			:class="{'hidden':!epargner_shown}"/>
		<DialogUnlock @close="close" :compte="active_compte"
			:class="{'hidden':!unlock_shown}"/>
		<DialogBalance @close="close" :compte="active_compte"
			:class="{'hidden':!balance_shown}"/>
		<DialogSousCompte @close="close" :compte="active_compte"
			:class="{'hidden':!sous_compte_shown}"/>
	</div>
</template>
<script>
import DialogCustomer from "@/components/guichet/dialog_individu"
import DialogOrganisation from "@/components/guichet/dialog_organisation"
import DialogEpargner from "@/components/guichet/dialog_epargner"
import DialogUnlock from "@/components/guichet/dialog_unlock"
import DialogSousCompte from "@/components/guichet/dialog_sous_compte"
import DialogBalance from "@/components/cto/dialog_balance"

export default {
  props: {},
  components:{
  	DialogCustomer,
  	DialogOrganisation,
  	DialogUnlock,
  	DialogEpargner,
	  DialogBalance,
	  DialogSousCompte
	},
  data(){
  	return {
  		comptes: this.$store.state.comptes,
  		indiv_shown:false,
  		unlock_shown:false,
  		cheque_shown:false,
  		next:null,
  		active_compte:undefined,
  		keyword:"",
  		active_org:undefined,
  		org_shown:false,
  		epargner_shown:false,
  		balance_shown:false,
  		sous_compte_shown:false,
  		filtered_account:"",
  	}
  },
  watch:{
  	"$store.state.comptes"(new_val){
  		this.comptes = new_val;
  	},
  	keyword(new_val){
  		this.comptes = this.$store.state.comptes.filter( x => {
  		   return  JSON.stringify(x).toLowerCase().includes(new_val.toLowerCase())
		})
  	},
  },
  methods:{
  	close(e){
  		this.active_compte = null
  		this.indiv_shown = false;
  		this.org_shown = false;
  		this.unlock_shown = false;
  		this.cheque_shown = false;
  		this.epargner_shown = false;
  		this.balance_shown=false;
  		this.sous_compte_shown=false;
  		if(e){
  			if(e.modifier=="1")
  				this.editCustomer(e.compte)
  		}
  	},
  	isEpargne(compte){
  		return compte.epargne_end_date && new Date(compte.epargne_end_date) > new Date()
  	},
  	endEpargne(compte){
  		return compte.epargne_end_date && new Date(compte.epargne_end_date) <= new Date()
  	},
  	openProfile(compte_id){
  		this.$router.push(`/profile/${compte_id}`)
  	},
  	editCustomer(compte){
  		this.active_compte = compte
  		if(compte.organisation != null){
  			this.org_shown = true
  		} else {
  			this.indiv_shown = true
  		}
  	},
  	changeBalance(compte){
  		this.active_compte = compte
  		this.balance_shown = true
  	},
  	epargner(compte){
  		this.active_compte = compte
  		this.epargner_shown = true
  	},
  	unlockCompte(compte){
  		this.active_compte = compte
  		this.unlock_shown = true
  	},
  	loadMore(){
		axios.get(this.next, this.headers)
		.then((response) => {
			this.$store.state.comptes.push(...response.data.results)
			this.next = response.data.next
		}).catch((error) => {
			this.displayErrorOrRefreshToken(error, this.loadMore)
		})
  	},
  	lockCompte(compte){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
  		if(confirm("Voulez-vous vraiment reverouiller ce compte?")){
				axios.get(this.url+`/compte/${compte.id}/verrouiller/`, this.headers)
				.then((response) => {
					compte.unlock_type = response.data.unlock_type
					compte.balance = response.data.balance
				}).catch((error) => {
					console.error(error)
				})
  		}
  	},
  	removeCompte(compte){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
  		let confirm = this.active_user.first_name+" "+this.active_user.last_name;
  		if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
				axios.delete(this.url+`/compte/${compte.id}/`, this.headers)
				.then((response) => {
					compte.is_active = false
				}).catch((error) => {
					this.displayErrorOrRefreshToken(error, this.removeCompte)
				})
  		} else {
  			alert("ABANDONÉE")
  		}
  	},
  	rechercher(){
			axios.get(this.url+`/compte/?search=${this.keyword}`, this.headers)
			.then((response) => {
				this.$store.state.comptes = response.data.results
				this.next = response.data.next
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.rechercher)
			})
  	},
  	getSousComptes(){
  		axios.get(this.url+`/compte/sous-comptes/`, this.headers)
			.then((response) => {
				this.$store.state.comptes = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.rechercher)
			})
  	},
  	getComptesEpargne(){
  		axios.get(this.url+`/compte/comptes-epargne/`, this.headers)
			.then((response) => {
				this.$store.state.comptes = response.data
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.rechercher)
			})
  	},
  	getComptesInIsigiInfo(){
  		axios.get(this.url+`/compte/?info=true`, this.headers)
			.then((response) => {
				this.$store.state.comptes = response.data.results
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.rechercher)
			})
  	},
		liberer(compte){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      let confirm = this.active_user.first_name+" "+this.active_user.last_name;
  		if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
			axios.get(this.url+`/compte/${compte.id}/liberer/`, this.headers)
			.then(() => {
				compte.epargne_start_date=null
				compte.epargne_end_date=null
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.liberer)
			})
				}else{
		  	alert("ABANDONÉE")
		  }
		},
		addCheques(compte){
			axios.get(this.url+`/compte/${compte.id}/add-cheque/`, this.headers)
	    .then((response) => {
	    	alert("Success")
	    }).catch((error) => {
	      this.displayErrorOrRefreshToken(error, this.addCheques)
	    })
		},
		dupliquer(compte){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }else{
				this.active_compte = compte
	  		this.sous_compte_shown = true
      }
		},
	  fetchComptes(){
			axios.get(this.url+"/compte/", this.headers)
			.then((response) => {
				this.$store.state.comptes = response.data.results
			}).catch((error) => {
				this.displayErrorOrRefreshToken(error, this.fetchComptes)
			})
    }
  },
};
</script>
<style scoped>
.container {
  min-height: calc(100vh - 130px);
}
div.add{
	width: 150px;
	text-align: center;
	cursor: default;
}
td{
	user-select: none;
}
tfoot{
	position:sticky;
	bottom:0;
	background-color:white;
}
tfoot tr{
	color:white;
	height:30px;
	font-size:18px;
	background-color:var(--primary)
}
.disabled{
	background-color: rgb(195, 92, 92);
	color: white;
}
</style>
