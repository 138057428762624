<template>
  <div class="container">
    <PrintableHeader :client="compte" :intervalle="{du:compte.epargne_start_date,au:compte.epargne_end_date}"/>
    <div class="row nonprintable">
      <button class="btn-sm bg-primary" @click="imprimer">Imprimer</button>
    </div>
    <div class="table" :class="{'nonprintable':!can_print}">
      <table>
        <thead>
          <tr>
            <th>Mois</th>
            <th>Montant</th>
            <th>cumul</th>
            <th>benefices</th>
            <th>total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="depot in depots">
            <td>{{ depot.mois }}</td>
            <td>{{ money(depot.montant) }}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <PrintableFooter/>
  </div>
</template>
<script>
import PrintableHeader from "@/components/printable_header"
import PrintableFooter from "@/components/printable_footer"

export default {
  components: { PrintableHeader,PrintableFooter },
  data(){
    return {
      can_print:false,
      compte:{},
      depots:{},
    }
  },
  watch:{
    compte(new_val){
      if(new_val) this.fetchData()
    },
    can_print(new_val){
      if(new_val){
        setTimeout(() => print(), 100)
      }
    },
/*    depots(new_val){
      if(!new_val || new_val.length == 0) return
      let str_date = ""
      let dates = []
      let depot, old_cumul = 0, total = 0
      for(var i=new_val.length-1; i>=0; i--){
        depot = new_val[i] 
        str_date = this.monthYear(depot.date)
        total += depot.amount
        if(!this.grouped_depots[str_date]){
          dates.push(str_date)
          if(old_cumul==0)
            old_cumul=depot.amount*0.2
          this.grouped_depots[str_date] = {
            date:str_date,
            amount:depot.amount,
            benefice:old_cumul*0.02,
            cumul:total,
            total:total + old_cumul*0.02
            
          }
        } else {
            this.grouped_depots[str_date].date += str_date
            this.grouped_depots[str_date].amount += depot.amount

            this.grouped_depots[str_date].benefice += old_cumul*0.02
            this.grouped_depots[str_date].cumul += total
            this.grouped_depots[str_date].total += total + old_cumul*0.02
        }
        old_cumul = total
      }
      this.dates = dates
      this.fillEmptyDates()
    }*/
  },
  methods:{
    /*monthYear(isotime){
        let date = new Date(isotime)
        return `${date.getMonth()+1}-${date.getFullYear()}`.padStart(7, "0")
    },*/
/*    fillEmptyDates(){
      let dates = []
      let base_date = new Date(this.compte.epargne_start_date)
      let first_month = base_date.getMonth()+1
      let first_year = base_date.getFullYear()

      let final_date = new Date(this.compte.epargne_end_date)
      let last_month = final_date.getMonth()+1
      let last_year = final_date.getFullYear()

      if(base_date.getDate() > 15){
        first_month += 1
      }

      let months = last_month - first_month + (last_year - first_year) * 12 + (last_year - first_year)
      let count_base
      for(var i=first_month; i<first_month+months; i++){
        count_base = i % 13
        dates.push(`${count_base}-${first_year}`.padStart(7, "0"))
        if(count_base == 12){
          i += 1
          first_year += 1
        }
      }
      let temp_depot = null
      let depot
      for(let date of dates){
        if(!this.grouped_depots[date]){
          if(!!temp_depot){
            this.grouped_depots[date] = {
              date: date,
              amount: 0,
              benefice: temp_depot.cumul*0.02,
              cumul: temp_depot.cumul,
              total: temp_depot.total + temp_depot.cumul*0.02
            }
          } else {
            this.grouped_depots[date] = {
              date:date,
              amount:0,
              benefice:0,
              cumul:0,
              total:0
            }
          }
          temp_depot = this.grouped_depots[date]
        } else {
          depot = this.grouped_depots[date]
          if(!!temp_depot){
            depot.benefice = temp_depot.cumul*0.02
            depot.cumul = temp_depot.cumul + depot.amount
            depot.total = temp_depot.cumul + depot.amount + temp_depot.cumul*0.02
          }
          temp_depot = depot
        }
      }
      this.dates = dates
    },*/
    getCompte(id){
      axios.get(this.url+`/compte/${id}/`, this.headers)
      .then((response) => {
            this.compte = response.data
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.getCompte)
      })
    },
    /*calcCumul(depot,prevId){
      if(prevId>=0)
        return 
    },*/
    fetchData(){
      let id = this.$route.params.id
      axios.get(this.url+`/depot/depots-epargnes/${id}/`, this.headers)
      .then((response) => {
            this.depots = response.data
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.fetchData)
      })
    },
/*    fetchData(){
      let link;
      let args = `?account=${this.compte.id}&epargne=true`+
        `&date__gte=${this.compte.epargne_start_date}T00:00`+
        `&date__lte=${this.compte.epargne_end_date}T23:59`
      if(!this.next){
        this.depots = []
        link = this.url+`/depot/`+args
      } else {
        link = this.next
      }
      axios.get(link, this.headers)
      .then((response) => {
        this.depots.push(...response.data.results)
        if(response.data.next != null){
          this.next = response.data.next
          this.fetchData()
        } else {
          this.next = null
        }
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.fetchData)
      })
    },*/
    imprimer(){
        this.can_print=true;
    }
  },
  mounted(){
    let id = this.$route.params.id;
    this.compte = this.$store.state.comptes.find(x => x.id==id)
    if(!this.compte){
      this.getCompte(id)
    }
    
  }
};
</script>
<style scoped>
.container {
    min-height: calc(100vh - 180px);
}
.row {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  align-items: flex-end;
}
.left{
  display: flex;
  align-items: flex-end;
}
input[type='date'], select{
  width: 200px;
  outline: none;
  border: 2px solid var(--primary);
}
.field label{
  color: var(--primary);
  font-weight: 700;
}
.field{
  padding: 0 10px 0 0;
  margin-bottom: 0;
}
.buttons{
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}
.btn-sm{
  margin: 0 5px;
}
@media screen and (max-width:800px) {
  .row {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
  }
}
.money{
  white-space: nowrap;
}
@media screen and (max-width:600px) {
  .container {
    padding: 0 20px;
  }
  .row {
    margin: 10px auto;
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
