<template>
  <div class="container compt-pads">
    <div class="head">
      <div><h1>Compte de Résultat</h1></div>
      <div class="form">
        <div>Selectionner l'année:</div>
        <select id="" v-model="annee">
          <option class="option" v-for="year in years" :value="year">
            {{ year }}
          </option>
        </select>
        <button @click="fetchData(date)" class="btn-sm bg-primary">
          Filter
        </button>
      </div>
    </div>
    <div class="table">
      <div class="entete">
        <div class="info">
          <div>NOM:</div>
          <div>NIF:</div>
          <div>Adresse:</div>
        </div>
        <div class="title">
          <div>COMPTE DE RESULTAT</div>
          <div>Exercice clos le .../.../{{ annee }}</div>
        </div>
      </div>
      <SpinnerComp v-if="loading"/>
      <div class="tables" v-else>
        <div>
          <table>
            <tr>
              <td></td>
              <td>CLASSE 7 : PRODUITS</td>
              <td colspan="3">ANNEES</td>
            </tr>
            <tr>
              <td>Compte</td>
              <td></td>
              <td>{{ annee }}</td>
              <td>{{ annee-1 }}</td>
            </tr>
            <tr 
              :class="{ 'thead': compte.plan_comptable.length == 2}"
              v-for="compte in comptes_resultats_produits.filter(x => x.plan_comptable[0] == 7)">
              <td>{{ compte.plan_comptable }}</td>
              <td>{{ compte.nom }}</td>
              <td class="money">{{ money(compte.montant_brut) }}</td>
              <td class="money">{{ money(compte.ancien_montant) }}</td>
            </tr>
            <tr>
              <td colspan="2">Total</td>
              <td class="money">{{ money(totaux(comptes_resultats_produits, 'montant_brut')) }}</td>
              <td class="money">{{ money(totaux(comptes_resultats_produits, 'ancien_montant')) }}</td>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr></tr>
            <tr>
              <td></td>
              <td>CLASSE 6 : CHARGES</td>
              <td colspan="3">ANNEES</td>
            </tr>
            <tr>
              <td>Compte</td>
              <td></td>
              <td>{{ annee }}</td>
              <td>{{ annee-1 }}</td>
            </tr>
            <tr
              :class="{ 'thead': compte.plan_comptable.length == 2}"
              v-for="compte in comptes_resultats_charges.filter(x => x.plan_comptable[0] == 6)">
              <td>{{ compte.plan_comptable }}</td>
              <td>{{ compte.nom }}</td>
              <td class="money">{{ money(compte.montant_brut) }}</td>
              <td class="money">{{ money(compte.ancien_montant) }}</td>
            </tr>
            <tr>
              <td colspan="2">Total de charges</td>
              <td class="money">{{ money(totaux(comptes_resultats_charges, 'montant_brut')) }}</td>
              <td class="money">{{ money(totaux(comptes_resultats_charges, 'ancien_montant')) }}</td>
            </tr>
            <tr>
              <td colspan="2">Resultat</td>
              <td class="money">{{ money(
                totaux(comptes_resultats_produits, 'montant_brut') -
                totaux(comptes_resultats_charges, 'montant_brut')
              ) }}</td>
              <td class="money"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SpinnerComp from "@/components/spinner_comp"

export default {
  components:{
    SpinnerComp
  },
  data() {
    return {
      loading: true,
      comptes_resultats_charges: [],
      comptes_resultats_produits: [],
      annee: new Date().getFullYear(),
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear() + 1;
      return Array.from(
        { length: year - 2023 }, (value, index) => 2023 + index
      );
    },
  },
  methods: {
    fetchDataCharges() {
      this.loading = true
      this.headers.params = {
        "plan_comptable__category": "CHARGE",
        "annee": this.annee
      }
      axios.get(this.url + `/bilan_comptables/`, this.headers)
      .then((res) => {
        this.comptes_resultats_charges = res.data.results;
      }).catch((err) => {
        this.displayErrorOrRefreshToken(err, this.fetchDataCharges);
      }).finally(() => {
        this.loading = false
      });
    },
    fetchDataProduits() {
      this.loading = true
      this.headers.params = {
        "plan_comptable__category": "PRODUIT",
        "annee": this.annee
      }
      axios.get(this.url + `/bilan_comptables/`, this.headers)
      .then((res) => {
        this.comptes_resultats_produits = res.data.results;
      }).catch((err) => {
        this.displayErrorOrRefreshToken(err, this.fetchDataProduits);
      }).finally(() => {
        this.loading = false
      });
    },
    fetchData() {
      this.loading = true
      let url = this.url + "/bilan_comptables/";
      url += `?annee=${this.annee}`;
      axios.get(url, this.headers)
      .then((res) => {
        this.fetchDataCharges()
        this.fetchDataProduits()
      }).catch((err) => {
        this.displayErrorOrRefreshToken(err, this.fetchData);
      }).finally(() => {
        this.loading = false
      });
    },
    totaux(classe, colonne) {
      let doubled = classe.filter(x => x.plan_comptable.length == 2)
      return doubled.reduce((acc, x) => acc + x[colonne], 0);
    },
  },
  mounted() {
    this.fetchDataCharges();
    this.fetchDataProduits();
  },
};
</script>
<style scoped>
.form {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff3;
  display: flex;
  align-items: center;
  gap: 5px;
}
.tables{
  display: flex;
  gap: 10px;
  font-size: .8em;
}
.compte {
  display: flex;
  flex-direction: column;
}
.thead {
  background-color: rgb(227, 187, 77);
}
.entete {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 600;
}
.info {
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  flex-direction: column;
}
.head {
  display: flex;
  flex-direction: column;
}
.table {
  width: 100%;
}
th, td {    
	border-right: 1px solid #aaa;
}
.compt-pads {
    min-height: calc(100vh - 110px);
}
</style>
