<template>
  <Overlay @close="$emit('close')">
    <div class="field">
      <label for="montant">ACTION:</label>
      <input id="montant" type="text" v-model="histProp.action">
    </div>
    <div class="field">
      <label for="montant">BALANCE_COMPTE_AVANT:</label>
      <input id="montant" type="number" v-model="histProp.balance_compte_avant">
    </div>
    <div class="field">
      <label for="montant">BALANCE_COMPTE_APRES:</label>
      <input id="montant" type="number" v-model="histProp.balance_compte_apres">
    </div>
    <div class="field">
      <label for="montant">DETAILS:</label>
      <input id="montant" type="text" v-model="histProp.details">
    </div>
    <div class="field">
      <label for="date">DATE:</label>
      <input id="date" type="text" v-model="histProp.date">
    </div>
    <div class="field">
      <label class="logs">{{ logs }}</label>
    </div>
    <button class="btn" @click="postData">
      Soumettre
    </button>
  </Overlay>
</template>
<script>
import Overlay from "./dialog_overlay"
export default {
  components:{Overlay},
  props:["histProp"],
  data(){
    return {
      logs:""
    }
  },
  methods:{
    postData(){
      axios.put(this.url+"/historique/"+this.histProp.id+"/",this.histProp, this.headers )
      .then((response) => {
        console.log(response)
        this.$emit("close")
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.performAction)
      })
    }
  }
};
</script>
<style scoped>
.content{
  width: 150px!important;
}
</style>
