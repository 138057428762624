<template>
	<div class="container compt-pads">
		<div class="head">
			<div><h1>Flux de Trésorerie</h1></div>
		<div class="form">
			<div>Selectionner la date:</div>
			<input type="number" min="2023" max="2039" step="1" v-model="year" />
			<button @click="fetchData" class="btn-sm bg-primary">Filter</button>
			<button @click="fetchData(true)" class="btn-sm bg-primary">Recharger</button>
		</div>
		</div>
		<div class="table">
			<table>
				<thead>
					<tr>
						<th>N.</th>
						<th>Rubriques</th>
						<th>Notes</th>
						<th>{{ year }}</th>
						<th>{{ year - 1 }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>1.</td>
						<td>ACTIVITES OPERATIONNELLES</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>1.1</td>
						<td>Produits d'opération bancaire encaissés (hors  revenus du portefeille d'investissement)</td>
						<td></td>
						<td>{{ money(flux[11]?.montant) }}</td>
						<td>{{ money(flux[11]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.2</td>
						<td>Charges d'opération bancaire décaissée</td>
						<td></td>
						<td>{{ money(flux[12]?.montant) }}</td>
						<td>{{ money(flux[12]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.3</td>
						<td>Depot/Retrait sur depot  aupres d'autres institutions bancaires et financiere</td>
						<td></td>
						<td>{{ money(flux[13]?.montant) }}</td>
						<td>{{ money(flux[13]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.4</td>
						<td>Prèts et avances / Remboursement prêts et avances accordés à la clientèle, au personnel et aux dirigeants </td>
						<td></td>
						<td>{{ money(flux[14]?.montant) }}</td>
						<td>{{ money(flux[14]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.5</td>
						<td>Dépôts/Retraits sur dépôts de la clientèle</td>
						<td></td>
						<td>{{ money(flux[15]?.montant) }}</td>
						<td>{{ money(flux[15]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.6</td>
						<td>Titres de placements</td>
						<td></td>
						<td>{{ money(flux[16]?.montant) }}</td>
						<td>{{ money(flux[16]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.7</td>
						<td>Sommes versées au personnel et créditeurs divers</td>
						<td></td>
						<td>{{ money(flux[17]?.montant) }}</td>
						<td>{{ money(flux[17]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.8</td>
						<td>Autres flux de trésorerie provenant des opérations </td>
						<td></td>
						<td>{{ money(flux[18]?.montant) }}</td>
						<td>{{ money(flux[18]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>1.9</td>
						<td>Impôts sur les résultats</td>
						<td></td>
						<td>{{ money(flux[19]?.montant) }}</td>
						<td>{{ money(flux[19]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td></td>
						<td>FLUX DE TRESORERIE NET PROVENANTS DES ACTIVITES OPERATIONNELLES</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>2</td>
						<td>ACTIVITES D'INVESTISSEMENT</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>2.1</td>
						<td>Intérêts et dividendes encaissés sur les investissements</td>
						<td></td>
						<td>{{ money(flux[21]?.montant) }}</td>
						<td>{{ money(flux[21]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>2.2</td>
						<td>Acquisitions /cessions sur portefeuille d'investissement</td>
						<td></td>
						<td>{{ money(flux[22]?.montant) }}</td>
						<td>{{ money(flux[22]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>2.3</td>
						<td>Acquisitions /cession d'immobilisations</td>
						<td></td>
						<td>{{ money(flux[23]?.montant) }}</td>
						<td>{{ money(flux[23]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td></td>
						<td>FLUX DE TRESORERIE NET PROVENANTS DES ACTIVITES D'INVESTISSEMENTS</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>3</td>
						<td>ACTIVITES DE FINACEMENT</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>3.1</td>
						<td>Emission de capital</td>
						<td></td>
						<td>{{ money(flux[31]?.montant) }}</td>
						<td>{{ money(flux[31]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>3.2</td>
						<td>Emission d'emprunts</td>
						<td></td>
						<td>{{ money(flux[32]?.montant) }}</td>
						<td>{{ money(flux[32]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>3.3</td>
						<td>Remboursement d'emprunts</td>
						<td></td>
						<td>{{ money(flux[33]?.montant) }}</td>
						<td>{{ money(flux[33]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>3.4</td>
						<td>Augmentation / diminution ressources spéciales</td>
						<td></td>
						<td>{{ money(flux[34]?.montant) }}</td>
						<td>{{ money(flux[34]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>3.5</td>
						<td>Dividendes versés</td>
						<td></td>
						<td>{{ money(flux[35]?.montant) }}</td>
						<td>{{ money(flux[35]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td></td>
						<td>FLUX DE TRESORERIE NET PROVENANTS DES ACTIVITES DE FINANCEMENT</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>4</td>
						<td>Variation nette des liquidités et équivalents de liquidités au cours de l'exercice</td>
						<td></td>
						<td>{{ money(flux[4]?.montant) }}</td>
						<td>{{ money(flux[4]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td>5</td>
						<td>Liquidités et équivalents de liquidités en début d'exercice</td>
						<td></td>
						<td>{{ money(flux[5]?.montant) }}</td>
						<td>{{ money(flux[5]?.ancien_montant) }}</td>
					</tr>
					<tr>
						<td></td>
						<td>LIQUIDITES  ET EQUIVALENTS DE LIQUIDITES  EN FIN D'EXERCICE</td>
						<td></td>
						<td>{{ money(flux[6]?.montant) }}</td>
						<td>{{ money(flux[6]?.ancien_montant) }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	data(){
		return {
			flux: {},
			year: new Date().getFullYear()
		}
	},
	methods:{
		fetchData(reload=false){
			let params = { annee: this.year };
			this.headers.params = params
			this.loading = true
			let url = this.url + "/flux_tresoreries/"
			if(reload) url = this.url + `/flux_tresoreries/reload/${this.year}/`
			axios.get(url, this.headers).then((res) => {
				let flux = {}
				for(let item of res.data.results){
					flux[item.numero] = item
				}
				this.flux = flux
			}).catch((err) => {
				this.displayErrorOrRefreshToken(err, () => this.fetchData(reload));
			}).finally(() => {
				this.loading = false
			});
		}
	},
	mounted(){
		this.fetchData()
	}
}
</script>
<style scoped>
.form{
	margin: 10px 0;
	padding: 10px;
	background-color: #fff3;
	display: flex;
	align-items: center;
	gap: 10px;
}
input{
	padding: 3px 10px;
}
.head{
    display: flex;
    flex-direction: column;
}
.table{
	width: 100%;
}
th, td {    
	border-right: 1px solid #aaa;
}
.compt-pads {
    min-height: calc(100vh - 110px);
}
</style>
