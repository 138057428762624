<template>
	<div class="container compt-pads">
		<div class="head">
			<div><h1>Balance Géneral des Comptes</h1></div>
		<div class="form">
			<div>Selectionner la date:</div>
			<input type="date" name="" id="" v-model="date">
			<button @click="fetchData(date)" class="btn-sm bg-primary">Filter</button>
			<button @click="fetchData(date, 1)" class="btn-sm bg-primary">
				Detail
			</button>
		</div>
		</div>
		<SpinnerComp v-if="loading"/>
		<table class="table" v-else>
			<thead>
				<tr>
					<th>Compte</th>
					<th>Libellés</th>
					<th colspan="2">Solde au debut</th>
					<th colspan="2">Mouvements</th>
					<th colspan="2">Solde à la fin</th>
				</tr>
				<tr>
					<th></th>
					<th></th>
					<th>Débit</th>
					<th>Crédit</th>
					<th>Débit</th>
					<th>Crédit</th>
					<th>Débit</th>
					<th>Crédit</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="balance in balances">
					<td>{{ balance.plan_comptable }}</td>
					<td>{{ balance.compte }}</td>
					<td class="money">{{ money(balance.debit_initial) }}</td>
					<td class="money">{{ money(balance.credit_initial) }}</td>
					<td class="money">{{ money(balance.debit) }}</td>
					<td class="money">{{ money(balance.credit) }}</td>
					<td class="money">{{ soldeFinal(balance) > 0 ? money(soldeFinal(balance)) : 0 }}</td>
					<td class="money">{{ soldeFinal(balance) < 0 ? money(Math.abs(soldeFinal(balance))) : 0 }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import SpinnerComp from "@/components/spinner_comp"

export default {
	components:{
		SpinnerComp
	},
	data() {
    	return {
      		loading: true,
			balances:[],
			date: new Date()
		}
	},
	methods:{
		soldeFinal(balance){
			return (balance.debit_initial + balance.debit) - (balance.credit_initial + balance.credit)
		},
		fetchData(date, deep){
			this.loading = true
			let url = this.url+"/balance_generals/"
			let params = { deep: deep, date: date }
			this.headers["params"] = params
			axios.get(url, this.headers).then(res => {
				this.balances = res.data
			}).catch(err => {
          		this.displayErrorOrRefreshToken(err, this.fetchData)
			}).finally(() => {
				this.loading = false
			})
		},
	},
	mounted(){
		this.fetchData()
	}
}
</script>
<style scoped>
.form{
	margin: 10px 0;
	padding: 10px;
	background-color: #fff3;
	display: flex;
	align-items: center;
	gap: 5px;
}
.head{
    display: flex;
    flex-direction: column;
}
.table{
	width: 100%;
}
th, td {    
	border-right: 1px solid #aaa;
}
.compt-pads {
    min-height: calc(100vh - 110px);
}
</style>
