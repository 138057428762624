<template>
	<Overlay @close="$emit('close')">
		<div class="field">
			<label for="user">ID</label>
			<input id="user" type="number" v-model="id">
		</div>
		<div class="field">
			<label for="user">USER</label>
			<input id="user" type="number" v-model="user">
		</div>
		<div class="field">
			<label for="action">ACTION</label>
			<input id="action" type="text" v-model="action">
		</div>
		<div class="field">
			<label for="table">TABLE</label>
			<input id="table" type="text" v-model="table">
		</div>
		<div class="field">
			<label for="line">LINE</label>
			<input id="line" type="number" v-model="line">
		</div>
		<div class="field">
			<label for="compte">COMPTE</label>
			<input id="compte" type="number" v-model="compte">
		</div>
		<div class="field">
			<label for="balance_compte_avant">BALANCE_COMPTE_AVANT</label>
			<input id="balance_compte_avant" type="number" v-model="balance_compte_avant">
		</div>
		<div class="field">
			<label for="balance_compte_apres">BALANCE_COMPTE_APRES</label>
			<input id="balance_compte_apres" type="number" v-model="balance_compte_apres">
		</div>
		<div class="field">
			<label for="details">DETAILS</label>
			<input id="details" type="text" v-model="details">
		</div>
		<div class="field">
			<label for="date">DATE</label>
			<input id="date" type="date" v-model="date">
		</div>
		<button class="btn" @click="createHistory">
			Creer Historique
		</button>
	</Overlay>
</template>
<script>
import Overlay from "../dialog_overlay";
export default {
	components: { Overlay },
	data() {
		return {
			id:"",
			user:"",
			action:"Depot",
			table:"Depot",
			date : null,
			line:"",
			compte:"",
			balance_compte_avant:"",
			balance_compte_apres:"",
			details:"Depot De 20000.0 Au Compte N°02022-A0000469/ISIGI Depositaire : NTAKIYIRUTA Jesus Armel",
		};
	},
	methods: {
		createHistory() {
			let data = {
				user:this.user,
				action:this.action,
				table:this.table,
				line:this.line,
				date:this.date,
				compte:this.compte,
				balance_compte_avant:this.balance_compte_avant,
				balance_compte_apres:this.balance_compte_apres,
				details:this.details,
			}
			axios.post(this.url+"/historique/raw-hist/",data, this.headers)
			.then(() => {
				this.$emit("close");
			})
			.catch((error) => {
				this.displayErrorOrRefreshToken(error, this.createHistory);
			});
	},
}
};
</script>
<style scoped>
</style>
