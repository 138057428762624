<template>
	<Overlay v-if="compte" @close="$emit('close')">
		<div class="field">
			<label for="msg">Balance avant:</label>
			<input id="msg" type="number" v-model="compte.balance">
		</div>
		<div class="field">
			<label for="msg">Balance après:</label>
			<input id="msg" type="number" v-model="balance_apres">
		</div>

		<button class="btn" @click="changeBalance">
			Envoyer
		</button>
	</Overlay>
</template>
<script>
import Overlay from "../dialog_overlay";
export default {
	props: ["compte"],
	components: { Overlay },
	data() {
		return {
			balance_apres:0
		};
	},
	methods: {
		changeBalance() {
			axios.post(this.url+/user/+this.$store.state.user.id+"/change_balance/", {"compte_id":this.compte.id, "balance": this.balance_apres}, this.headers)
			.then(() => {
				this.$emit("close");
			})
			.catch((error) => {
				this.displayErrorOrRefreshToken(error, this.changeBalance);
			});
	},
}
};
</script>
<style scoped>
</style>
