<template>
  <div class="container">
    <PrintableHeader />
    <div class="row nonprintable">
      <div class="left">
        <div class="field">
          <label for="du">Du: </label>
          <input type="date" id="du" v-model="du" />
        </div>
        <div class="field">
          <label for="au">Au: </label>
          <input type="date" id="au" v-model="au" />
        </div>
        <div class="field">
          <label for="category">Type:</label>
          <select id="category" v-model="category">
            <option value="">
              TOUT
            </option>
            <option v-for="item in $store.state.PRODUITS" :value="item.numero">
              {{ item.nom }}
            </option>
          </select>
        </div>
        <button class="btn-sm bg-primary" @click="fetchData">Filtrer</button>
        <div class="buttons">
          <button class="btn-sm bg-success" @click="produit_shown = true">
            Créer
          </button>
          <button class="btn-sm bg-primary" @click="imprimer">Imprimer</button>
        </div>
      </div>
    </div>
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>date</th>
            <th>montant</th>
            <th>details</th>
            <th>user</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in history">
            <td>{{ datetime(record.date) }}</td>
            <td class="money">{{ money(record.montant) }}</td>
            <td>{{ record.details }}</td>
            <td>{{ record.user.first_name }} {{ record.user.last_name }}</td>
            <td>
              <button
                class="btn-sm bg-danger"
                @click="deleteProduit(record.id)"
              >
                <fa icon="trash" />
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3" class="center">Total</th>
            <th class="money">
              {{ money(history.reduce((acc, x) => (acc += x.montant), 0)) }}
            </th>
            <th colspan="2"></th>
          </tr>
        </tfoot>
      </table>
    </div>
    <DialogProduit :class="{ hidden: !produit_shown }" @close="close" />
  </div>
</template>
<script>
import DialogProduit from "@/components/mouvements/dialog_produit";
import PrintableHeader from "@/components/printable_header";

export default {
  components: { DialogProduit, PrintableHeader },
  data() {
    return {
      history: [],
      produit_shown: false,
      category: "",
      personnels_list: [],
      user_filter: "",
      du: this.getFirstDate(),
      au: this.getToday(),
    };
  },
  watch: {
    "$store.state.produits": {
      deep: true,
      handler(new_val) {
        this.history = new_val;
      },
    },
  },
  methods: {
    close() {
      this.produit_shown = false;
    },
    deleteProduit(id) {
      let confirm =
        this.active_user.first_name + " " + this.active_user.last_name;
      if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
        axios
          .delete(this.url + `/produit/${id}/`, this.headers)
          .then(() => {
            alert("Produit supprime avec success");
          })
          .catch((error) => {
            this.displayErrorOrRefreshToken(error, this.deleteProduit);
          });
      } else alert("ABANDONÉE");
    },
    getFirstDate() {
      let date = new Date();
      date.setDate(1);
      return date.toISOString().split("T")[0];
    },
    getToday() {
      return new Date().toISOString().split("T")[0];
    },
    fetchData() {
      let link;
      let date_params;

      if (!this.next) {
        this.$store.state.produits = [];
        date_params = `date__gte=${this.du}T00:00&date__lte=${this.au}T23:59`;
        link =
          this.url +`/produit/?${date_params}&category=${this.category}&user=${
            this.user_filter ? this.user_filter : ""
          }`;
      } else {
        link = this.next;
      }
      axios
        .get(link, this.headers)
        .then((response) => {
          // if (response.data.results.length == 0) {
          //   date_params = `date__gte=${this.du}T00:00&date__lte=${this.au}T23:59`;
          //   link =
          //     this.url +
          //     `/produit/?${date_params}&category=${this.category}&user=${
          //       this.user_filter ? this.user_filter : ""
          //     }`;
          //   this.refetchData(link);
          // }
          // else {
          this.$store.state.produits.push(...response.data.results);
          if (response.data.next != null) {
            this.next = response.data.next;
            this.fetchData();
          } else {
            this.next = null;
          }
          // }
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchData);
        });
    },
    refetchData(link) {
      axios
        .get(link, this.headers)
        .then((response) => {
          this.$store.state.produits.push(...response.data.results);
          if (response.data.next != null) {
            this.next = response.data.next;
            this.fetchData();
          } else {
            this.next = null;
          }
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchData);
        });
    },
    fetchUsers() {
      axios
        .get(this.url + "/personnel/", this.headers)
        .then((response) => {
          this.personnels_list = response.data.results;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchUsers);
        });
    },
    fetchOptions(){
      if(this.$store.state.PRODUITS.length != 0) return
      axios.get(this.url +`/plan_comptable/load_produits/`, this.headers)
      .then((response) => {
        this.$store.state.PRODUITS = response.data;
      })
      .catch((error) => {
        this.displayErrorOrRefreshToken(error, this.fetchData);
      });
    },
    imprimer() {
      print();
    },
  },
  mounted() {
    this.fetchUsers();
    if (this.$store.state.produits.length == 0) {
      this.fetchData();
      this.fetchOptions()
    } else {
      this.history = this.$store.state.produits;
    }
  },
};
</script>
<style scoped>
.container {
  min-height: calc(100vh - 120px);
}
.row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 90%;
  margin: 10px auto;
}
input[type="date"],
select {
  height: 28px;
  width: 200px;
  outline: none;
  border: 2px solid var(--primary);
}
.btn-sm {
  margin-bottom: 0;
}
.buttons {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;
}
.field label {
  color: var(--primary);
  font-weight: 700;
}
.field {
  padding: 0 10px 0 0;
  margin-bottom: 0;
}
.left {
  display: flex;
  align-items: flex-end;
  width: 90%;
}
@media screen and (max-width: 1024px) {
  .container {
    display: block;
    position: relative;
    justify-content: flex-start;
  }
  .nonprintable {
    width: 60%;
    margin: 10px auto;
    display: block;
    height: auto;
  }
}
@media screen and (max-width: 800px) {
  .row {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .container {
    padding: 0 20px;
  }
  .row {
    margin: 10px auto;
    display: block;
    width: 100%;
    height: auto;
  }
  .left {
    flex-direction: column;
  }
}
</style>
