<template>
  <Overlay @close="$emit('close')">
    <div class="field">
      <label for="montant">Montant:</label>
      <input id="montant" type="number" v-model="montant">
      <small v-if="montant">{{ money(montant) }}</small>
    </div>
    <div class="field">
      <label for="date">Date:</label>
      <input id="date" type="datetime-local" v-model="date">
    </div>
    <div class="field">
      <label for="category">Type:</label>
      <select id="category" v-model="category">
        <option v-for="item in $store.state.PRODUITS" :value="item.numero">
          {{ item.numero }} - {{ item.nom }}
        </option>
      </select>
    </div>
    <div class="field">
      <label for="details">Details:</label>
      <textarea id="details" v-model="details">
      </textarea>
    </div>
    <div class="field">
      <label class="logs">{{ logs }}</label>
    </div>
    <button class="btn" @click="postData">
      Soumettre
    </button>
  </Overlay>
</template>
<script>
import Overlay from "../dialog_overlay"
export default {
  components:{Overlay},
  props:["produitProps"],
  data(){
    return {
      montant:0,
      details:"",
      category:"",
      date:this.currentDateTime(),
      logs:""
    }
  },
  watch:{
    produitProps(new_val){
      if (!!new_val) {
        this.montant = new_val.montant
        this.details = new_val.details
        this.category = new_val.category
        this.date = new_val.date
      }else{
        this.montant = 0
        this.details = 0
        this.category = ""
        this.date = null
      }
    }
  },
  computed:{
    INCOME_TYPES(){
      return Object.entries(this.$store.state.INCOME_TYPES)
    },
  },
  methods:{
    currentDateTime(){
      var m = new Date()
      var dateString =
      m.getUTCFullYear() +"-"+
      ("0"+(m.getUTCMonth()+1)).slice(-2)  +"-"+
      ("0"+m.getUTCDate()).slice(-2)  + "T" +
      ("0"+(m.getUTCHours()+2)).slice(-2)  +":"+
      ("0"+m.getUTCMinutes()).slice(-2)
      return dateString;
    },
    postData(){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      let data = {
        montant:this.montant,
        details:this.details,
        category:this.category,
        date:this.date
      }
      if(!this.produitProps)
        axios.post(this.url+"/produit/", data, this.headers )
        .then((response) => {
          this.$store.state.produits.push(response.data)
          this.$emit("close")
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.postData)
        })
      else{
        axios.put(this.url+"/produit/"+this.produitProps.id+"/", data, this.headers )
        .then((response) => {
          this.updateArrayAfterUpdate(
            this.$store.state.produits,
            response.data
            )
          this.$emit("close")
        }).catch((error) => {
          this.displayErrorOrRefreshToken(error, this.postData)
        })
      }
    }
  }
};
</script>
<style scoped>
.content{
  width: 150px!important;
}
</style>
