<template>
  <Overlay @close="$emit('close')">
      <div class="field">
        <label for="pl_end_date">Date de cloture:</label>
        <input id="pl_end_date" type="date" v-model="pl_end_date">
      </div>
    <div class="field">
      <label class="logs">{{ logs }}</label>
    </div>
    <button class="btn" @click="cloturerPlacement">
      Calculer Interet
    </button>
  </Overlay>
</template>
<script>
import Overlay from "./dialog_overlay"
export default {
  props:["placementProps"],
  components:{Overlay},
  data(){
    return {
      pl_end_date:"", logs:"", mouvement:""
    }
  },
  watch:{
    mouvement(new_val){
      console.log(new_val)
    }
  },
  methods:{
    cloturerPlacement(){
      if(this.active_user_is('readonly')){
        this.$store.state.alert = {
          type:"danger",
          message:"Vous êtes en lecture seule"
        }
        return
      }
      let data = {
        pl_end_date: this.pl_end_date,
      }
      axios.post(this.url+`/placement/${this.placementProps.id}/calc-pl/`,data, this.headers)
      .then((response) => {
        this.$emit('closePlCalculated', response.data, {id:this.placementProps.id})
      }).catch((error) => {
        this.displayErrorOrRefreshToken(error, this.cloturerPlacement)
      });
    }
  }
};
</script>
<style scoped>
.radio-mouvement{
  display: flex;
  justify-content: space-around;
  margin: 20px;
  align-items: center;
}
</style>
