<template>
  <div class="header printable">
    <div class="right">
      <h3>Validation de l'historique</h3>
      <div>
        Nom et signature
      </div>
    </div>
    <img src="/static/logo.png" width="100px">
  </div>
</template>
<script>
</script>
<style scoped>
</style>
