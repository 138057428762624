<template>
  <div class="printable">
    <div class="header">
      <div class="right">
        <h2>ISIGI-MICROFINANCE</h2>
        <h3>KIBIMBA-GIHETA (SIEGE)</h3>
        <div>Tel: <b>+257 71 946 659/69 703 619</b></div>
      </div>
      <img src="/static/logo.png" width="100px" />
    </div>
    <div v-if="client">
      <div class="center"><b>Informations client et autres</b></div>
      <div class="client-info">
        <div>
          <div>Nom & Prenom</div>
          <div>Num compte</div>
          <div>Contact</div>
          <div>Adresse</div>
          <div v-if="intervalle">Date Intervalle</div>
          <div v-if="intervalle">Date</div>
        </div>
        <div>
          <div>{{ client.first_name }} {{ client.last_name }}</div>
          <div>{{ client.account_number }}</div>
          <div>{{ client.phone_number }}</div>
          <div>{{ client.adress }}</div>
          <div v-if="intervalle">{{ intervalle.du }}/{{ intervalle.au }}</div>
          <div v-if="intervalle">{{ datetime(new Date().getTime()) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["intervalle", "client"],
};
</script>
<style scoped>
.client-info {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--primary);
  margin: auto;
  max-width: 90vw;
}
</style>
