<template>
  <div class="wrapper nonprintable">
    <div class="content">
      <span class="open" @click="openNav">&#9776;</span>
      <div class="menu">
        <div class="close" @click="closeNav(navigate)">&times;</div>
        <div class="content-item">
          <router-link to="/" v-slot="{ href, navigate, isExactActive }">
            <div class="menu-item" @click="closeNav(navigate)">
              <a
                :href="href"
                :class="{ selected: isExactActive }"
               >HOME</a>
            </div>
          </router-link>
        </div>
        <div class="content-item">
          <div class="menu-item parent-menu" @click="closeNav(navigate)">
            <a>MENU</a>
            <div class="submenu">
              <div v-if="active_user_is('readonly', 'admin')">
                <router-link
                  to="/agence"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Agences
                    </a>
                  </div>
                </router-link>
              </div>
              <div v-if="active_user_is('responsable', 'admin', 'readonly')">
                <router-link
                  to="/cheque"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Cheques
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link to="/credit"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="active_user_is('responsable', 'admin', 'readonly')">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Credit
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link
                  to="/guichet"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Guichet
                    </a>
                  </div>
                </router-link>
              </div>
              <div v-if="active_user_is('responsable', 'admin', 'readonly')">
                <router-link
                  to="/placement"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Placement
                    </a>
                  </div>
                </router-link>
              </div>
              <div v-if="active_user_is('responsable', 'admin', 'readonly')">
                <router-link
                  to="/quittances"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Quittances
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link
                  to="/utilisateur"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Utilisateurs
                    </a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="content-item" v-if="active_user_is('readonly', 'admin', 'comptable')">
          <div class="menu-item parent-menu" @click="closeNav(navigate)">
            <a>MOUVEMENTS</a>
            <div class="submenu">
              <div v-if="active_user_is('readonly', 'admin', 'comptable')">
                <router-link
                  to="/investissements"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Investissements
                    </a>
                  </div>
                </router-link>
                <router-link
                  to="/passifs"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Passifs
                    </a>
                  </div>
                </router-link>
                <router-link
                  to="/produits"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Produits
                    </a>
                  </div>
                </router-link>
                <router-link
                  to="/charges"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Charges
                    </a>
                  </div>
                </router-link>
                <router-link
                  to="/autres"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Autres
                    </a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="content-item" v-if="active_user_is('readonly', 'admin', 'comptable', 'cto')">
          <div class="menu-item parent-menu" @click="closeNav(navigate)">
            <a>HISTORIQUE</a>
            <div class="submenu">
              <div v-if="active_user_is('readonly', 'admin')">
                <router-link to="/hist-user" v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Guichets
                    </a>
                  </div>
                </router-link>
              </div>
              <div v-if="active_user_is('readonly', 'admin')">
                <router-link to="/main-history" v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Compte Principal
                    </a>
                  </div>
                </router-link>
              </div>
              <div v-if="active_user_is('readonly', 'admin', 'cto')">
                <router-link to="/agence-history" v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Agence
                    </a>
                  </div>
                </router-link>
              </div>
              <div v-if="active_user_is('readonly', 'admin', 'comptable')">
                <router-link to="/rapport" v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      B.R.B
                    </a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="content-item">
          <router-link to="/parametres" v-slot="{ href, navigate, isActive, isExactActive }">
            <div class="menu-item" @click="closeNav(navigate)">
              <a :href="href" :class="{ selected: isActive }">
                PARAMETRES
              </a>
            </div>
          </router-link>
        </div>
        <div v-if="active_user_is('admin', 'comptable')" class="content-item">
          <div class="menu-item parent-menu" @click="closeNav(navigate)">
            <a>COMPTABILITE</a>
            <div class="submenu">
              <div>
                <router-link to="/comptabilite/plancomptable" v-slot="{ href, navigate, isActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }" click="navigate">
                      Plan Comptable
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link
                  to="/comptabilite/journal"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Journal
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link
                  to="/comptabilite/grandlivre"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Grand Livre
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link to="/comptabilite/balance" v-slot="{ href, navigate, isActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Balance
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link to="/comptabilite/bilan" v-slot="{ href, navigate, isActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Bilan
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link
                  to="/comptabilite/compte-resultat"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Compte de Resultat
                    </a>
                  </div>
                </router-link>
              </div>
              <div>
                <router-link
                  to="/comptabilite/flux-tresorerie"
                  v-slot="{ href, navigate, isActive, isExactActive }">
                  <div class="submenu-item" @click="closeNav(navigate)">
                    <a :href="href" :class="{ selected: isActive }">
                      Flux de Tresorerie
                    </a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-infos" @click="show_context = true" @mouseleave="show_context = false">
        <div class="username">
          {{ `${active_user.first_name} ${active_user.last_name}` }}
          <h6>{{ active_user.groups.join(", ") }}</h6>
        </div>

        <div class="context" v-if="show_context">
          <div @click="logout" class="item">
            <fa icon="sign-out-alt" /> Deconnexion
          </div>
          <div class="item" @click="password_shown = true">
            <fa icon="pen" /> Mot de Passe
          </div>
        </div>
      </div>
    </div>
    <DialogPassword @close="close" :class="{ hidden: !password_shown }" />
  </div>
</template>
<script>
import DialogPassword from "./dialog_password";

export default {
  components: {
    DialogPassword,
  },
  data() {
    return {
      show_context: false,
      password_shown: false,
    };
  },
  methods: {
    logout() {
      this.$store.state.user = null;
    },
    openNav() {
      var nav = document.querySelector(".menu");
      nav.classList.add("menu-active");
    },
    closeNav(navigate) {
      if(!!navigate) navigate()
      else return
      var nav = document.querySelector(".menu");
      nav.classList.remove("menu-active");
    },
    close() {
      this.password_shown = false;
    },
    performPrint() {
      print();
    },
  },
};
</script>
<style scoped>
.wrapper {
  background: var(--primary);
  position: relative;
  width: 100%;
}
.content {
  display: flex;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.content{
  padding: 5px 0;
}
.context {
  position: absolute;
  background-color: white;
  color: black;
  margin-left: auto;
  box-shadow: 0 2px 5px 3px var(--primary);
  cursor: pointer;
}
.content .item {
  padding: 5px;
}
.content .item:hover {
  background-color: var(--secondary);
  color: white;
}
.open {
  cursor: pointer;
  font-size: 26px;
  display: none;
  color: white;
}
.close {
  margin-left: auto;
  margin-right: 20px;
  font-size: 28px;
  font-weight: 600;
  color: white;
  display: none;
  cursor: pointer;
}
.content-item {
  display: inline-block;
}

.menu-item a {
  display: block;
  position: relative;
  padding: 3px 5px;
  text-decoration: none;
  font-size: 16px;
  color: white;
  transition: 0.5s;
  font-weight: 500;
}
.submenu a {
  font-size: 14px;
  padding: 3px;
}
.menu-item a.selected::after, .menu-item a:hover::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 50%;
  background: white;
  transform: translateX(50%);
}
.submenu-item a.selected::after, .submenu-item a:hover::after {
  width: 100%;
  transform: translateX(0%);
}
.submenu-item{
  font-size: 12px;
}
.submenu-item a.selected, .submenu-item a:hover {
  background-color: var(--primary-hover);
  color: var(--primary);
}
.parent-menu {
  position: relative;
}
.submenu {
  position: absolute;
  z-index: 2;
  background-color: var(--primary);
  box-shadow: 3px 3px 5px black;
  width: 160px;
  display: none;
  border: 1px solid var(--primary-hover);
}
.parent-menu:hover .submenu {
  display: block;
}
.user-infos {
  height: 100%;
  align-items: center;
  color: white;
}
.username {
  font-weight: 500;
  margin-right: 20px;
  font-size: 16px;
  cursor: pointer;
}
.user-infos button {
  border: none;
  outline: none;
  font-size: 22px;
  cursor: pointer;
  background: transparent;
}
.menu-item .green {
  color: #8cf;
  font-weight: 700;
}
.menu {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .open {
    display: block;
  }
  .close {
    display: block;
    width: 22px;
  }
  .content-item {
    display: block;
    padding: 12px 0;
  }
  .menu {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background: var(--primary);
    transform: translateX(-100%);
    transition: transform 0.5s ease-in;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .menu-active {
    transform: translateX(0%);
  }
  .menu-item a {
    position: unset;
    color: #ccc;
  }
  .menu-item a.selected, .menu-item a:hover {
    color: #fff;
  }
  .menu-item a.selected {
    font-weight: 800;
  }
  .menu-item a::after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .content {
    padding: 20px 30px 0;
  }
  .username {
    display: none;
  }
  .menu {
    width: 300px;
  }
}
</style>
