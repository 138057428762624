<template>
    <Overlay @close="$emit('close')">
            <div class="field input-checkbox">
                <label for="">Modifier ? : </label>
                <select required name="" id="" v-model="modifier">
                    <option value=1>Oui</option>
                    <option value=0>Non</option>
                </select>
            </div>
            <div class="field input-checkbox">
                <label for="">Avec frais ? : </label>
                <select required name="" id="" v-model="frais">
                    <option value=1>Oui</option>
                    <option value=0>Non</option>
                </select>
            </div>
        <div class="field">
          <label class="logs">{{ cleanString(logs).slice(0, 60) }}</label>
        </div>
        <button class="btn" @click="reprise">
          Créer sous-compte
        </button>
    </Overlay>
  </template>
  <script>
  import Overlay from "./dialog_overlay"
  export default {
    props:["compte"],
    components:{Overlay},
    data(){
      return {
        frais:"",modifier:"",logs:"",
      }
    },
    watch:{
      compte(new_val){
        if(!!new_val){
          this.personnel = new_val
        } else {
          this.personnel = {"user":{}}
          this.$emit("close")
        }
      }
    },
    methods:{
      reprise(){
        if(!this.cheque && !this.frais && !this.modifier){
          this.logs= "Veuillez choisir les parametres du sous comptes !"
          return
        }
        let data = {
          frais:this.frais,
        }
        let confirm = this.active_user.first_name+" "+this.active_user.last_name;
        if (prompt(`veuiller taper: "${confirm}" pour confirmer`) === confirm) {
          axios.post(this.url+`/compte/${this.compte.id}/dupliquer/`, data, this.headers)
          .then((response) => {
            console.log(response)
            this.$store.state.comptes.push(response.data.data)
            this.$emit("close",{"modifier":this.modifier,"compte":response.data.data})
          }).catch((error) => {
            this.displayErrorOrRefreshToken(error, this.dupliquer)
          })
        }else{
          alert("ABANDONÉE")
        }
      }
    }
  };
  </script>
  <style scoped>
  .input-button{
    display: flex;
  }

  .input-checkbox{
    display: flex;

  }
  .input-checkbox>label{
    width: 100%; 
  }
  .input-checkbox{
    display: flex;
    justify-content: flex-start;
    
  }
  .input-button>button{
    margin: 0 5px;
    padding: 0 5px;
    color: white;
    border: none;
    background-color: var(--primary);
  }
  .field {
    padding: 0 30px;
    margin-bottom: 8px;
  }
  .field label, .field input {
    display: block;
    width: 100%;
  }
  .field input[type='file'] {
    padding: 0;
    border: none;
  }
  .field label {
    margin-bottom: 5px;
  }
  .field input {
    height: 28px;
    border: none;
    outline: none;
    padding: 0 10px;
    border: 1px solid #55555555;
  }
  textarea {
    width: 100%;
    height: 80px;
    resize: none;
  }
  

  </style>
  