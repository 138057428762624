<template>
  <div class="container">
    <div class="row nonprintable">
      <div class="left">
        <div class="field" v-if="!is_date_exact">
          <label for="du">Du : </label>
          <input type="date" id="du" v-model="du" />
        </div>
        <div class="field" v-if="!is_date_exact">
          <label for="au">Au : </label>
          <input type="date" id="au" v-model="au" />
        </div>
        <button class="btn-sm bg-primary" @click="filterDate">Filtrer</button>
      </div>
    </div>
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>Heure</th>
            <th>Montant</th>
            <th>Catégorie</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(record, i) in main_history" :key="record.id">
            <td>{{ datetime(record.date) }}</td>
            <td>{{ money(record.amount) }}</td>
            <td>{{ record.category }}</td>
            <td>{{ record.details }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="money" colspan="3">
              Solde au début: <b>{{ money(last_total) }}</b>
              Solde à la fin: <b>{{ money(total) }}</b>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <DialogHist
      :class="{ hidden: !dialog_shown }"
      @close="dialog_shown = false"
    />
  </div>
</template>
<script>
import axios from "axios";
import DialogHist from "@/components/cto/dialog_create_hist";
export default {
  data() {
    return {
      du: "",
      au: "",
      history: [],
      total: 0,
      last_total: 0,
    };
  },
  computed: {
    main_history() {
      return this.history;
    },
  },
  methods: {
    fetchData(link) {
      axios
        .get(link, this.headers)
        .then((response) => {
          this.history.push(...response.data.results);
          let next = response.data.next || null;
          this.total = response.data.total;
          this.last_total = response.data.last_total;
          if (!!next) {
            this.fetchData(next);
          }
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, () =>
            this.fetchData(this.link_all)
          );
        });
    },
    filterDate() {
      let link;
      let du = !!this.du?`${this.du}T00:00`:''
      let au = !!this.au?`${this.au}T23:59`:''
      let period = `date__gte=${du}&date__lte=${au}`;

      if (!this.next) {
        link = this.url + `/main-history/?${period}`;
      } else {
        link = this.next;
      }
      axios.get(link, this.headers)
        .then((response) => {
          if (!response.data.previous) {
            this.history = response.data.results;
          } else {
            this.history.push(...response.data.results);
          }
          this.total = response.data.total;
          this.last_total = response.data.last_total;
          if (response.data.next != null) {
            this.next = response.data.next;
            this.filterDate();
          } else {
            this.next = null;
          }
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchHistory);
        });
    },
  },
  mounted() {
    this.link_all = this.url + "/main-history/"
    this.fetchData(this.link_all)
  }
};
</script>
<style scoped>
.container {
  min-height: calc(100vh - 130px);
}

.row {
  width: 90%;
  margin: 10px auto;
}

.left {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

input[type="date"] {
  height: 28px;
  width: 200px;
  outline: none;
  padding-bottom: 2px;
  border: 2px solid var(--primary);
}

.field label {
  color: var(--primary);
  font-weight: 700;
}

.field {
  padding: 0 10px 0 0;
  margin-bottom: 0;
}

.btn-sm {
  margin: 0 5px;
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 600px) {
}

.cto_higlight {
  color: red;
}

.cto_higlight span {
  color: blue;
  font-weight: none;
}
</style>
