<template>
  <div class="container">
    <div class="table">
      <div class="search-area">
        <input
          type="text"
          v-on:keyup.enter="rechercher"
          placeholder="rechercher"
          v-model="keyword"
        />
        <button class="search" @click="rechercher">Rechercher</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>No. compte</th>
            <th>Nom & Prenom</th>
            <th>Sexe</th>
            <th>CNI</th>
            <th>Somme</th>
            <th>Epargne</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="{ disabled: !compte.is_active }"
            v-for="(compte, i) in comptes"
            @click="(e) => activateRow(e)"
            @dblclick="openProfile(compte.id)"
          >
            <td>{{ i }}</td>
            <td>{{ compte.account_number }}</td>
            <td style="max-width: 150px">
              {{ compte.last_name }} {{ compte.first_name }}
            </td>
            <td>
              {{ getSexe(compte) }}
            </td>
            <td>{{ compte.CNI }}</td>
            <td>{{ !!compte.balance ? money(compte.balance) : "-" }}</td>
            <td>
              {{
                !!compte.balance_epargne ? money(compte.balance_epargne) : "-"
              }}
            </td>
            <td>
              <router-link
                :to="'/history-compte/' + compte.id"
                v-slot="{ navigate }"
              >
                <button class="btn-sm bg-secondary" @click.stop="navigate">
                  <fa icon="list-alt" />
                </button>
              </router-link>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5"></td>
            <td>
              {{
                money(
                  comptes.reduce(
                    (somme, compte) =>
                      (somme += compte.balance ? compte.balance : 0),
                    0
                  )
                )
              }}
            </td>
            <td>
              {{
                money(
                  comptes.reduce(
                    (somme, compte) =>
                      (somme += compte.balance_epargne
                        ? compte.balance_epargne
                        : 0),
                    0
                  )
                )
              }}
            </td>
            <td colspan="2"></td>
          </tr>
        </tfoot>
      </table>
      <a href="#" style="color: lightgray" @click="loadMore" v-if="!!next">
        gukwega ibindi...
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {},

  data() {
    return {
      comptes: this.$store.state.comptes,
      indiv_shown: false,
      unlock_shown: false,
      cheque_shown: false,
      next: null,
      active_compte: undefined,
      keyword: "",
      active_org: undefined,
      org_shown: false,
      epargner_shown: false,
      sms_shown: false,
      balance_shown: false,
      sous_compte_shown: false,
      filtered_account: "",
    };
  },
  watch: {
    "$store.state.comptes"(new_val) {
      this.comptes = new_val;
    },
    keyword(new_val) {
      this.comptes = this.$store.state.comptes.filter((x) => {
        return JSON.stringify(x).toLowerCase().includes(new_val.toLowerCase());
      });
    },
  },
  methods: {
    close(e) {
      this.active_compte = null;
      this.indiv_shown = false;
      this.org_shown = false;
      this.unlock_shown = false;
      this.cheque_shown = false;
      this.epargner_shown = false;
      this.sms_shown = false;
      this.balance_shown = false;
      this.sous_compte_shown = false;
      if (e) {
        if (e.modifier == "1") this.editCustomer(e.compte);
      }
    },
    isEpargne(compte) {
      return (
        compte.epargne_end_date &&
        new Date(compte.epargne_end_date) > new Date()
      );
    },
    endEpargne(compte) {
      return (
        compte.epargne_end_date &&
        new Date(compte.epargne_end_date) <= new Date()
      );
    },
    openProfile(compte_id) {
      this.$router.push(`/profile/${compte_id}`);
    },
    editCustomer(compte) {
      this.active_compte = compte;
      if (compte.organisation != null) {
        this.org_shown = true;
      } else {
        this.indiv_shown = true;
      }
    },
    changeBalance(compte) {
      this.active_compte = compte;
      this.balance_shown = true;
    },
    sendSMS(compte) {
      this.active_compte = compte;
      this.sms_shown = true;
    },
    epargner(compte) {
      this.active_compte = compte;
      this.epargner_shown = true;
    },
    unlockCompte(compte) {
      this.active_compte = compte;
      this.unlock_shown = true;
    },
    loadMore() {
      axios
        .get(this.next, this.headers)
        .then((response) => {
          this.$store.state.comptes.push(...response.data.results);
          this.next = response.data.next;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.loadMore);
        });
    },
    
    rechercher() {
      axios
        .get(this.url + `/comptes-history/?search=${this.keyword}`, this.headers)
        .then((response) => {
          console.log(this.keyword)
          this.$store.state.comptes = response.data.results;
          this.next = response.data.next;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.rechercher);
        });
    },
   
    fetchComptes() {
      axios
        .get(this.url + "/comptes-history/?get=comptes", this.headers)
        .then((response) => {
          this.$store.state.comptes = response.data.results;
        })
        .catch((error) => {
          this.displayErrorOrRefreshToken(error, this.fetchComptes);
        });
    },
  },
  mounted() {
    this.fetchComptes();
  },
};
</script>
<style scoped>
div.add {
  width: 150px;
  text-align: center;
  cursor: default;
}
td {
  user-select: none;
}
tfoot {
  position: sticky;
  bottom: 0;
  background-color: white;
}
tfoot tr {
  color: white;
  height: 30px;
  font-size: 18px;
  background-color: var(--primary);
}
.disabled {
  background-color: rgb(195, 92, 92);
  color: white;
}
</style>
